const getComponents = () => {
    return function (dispatch) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        // dispatch({ type: 'LOADING', response: true });
        fetch('/api/sf/components', requestOptions)
            .then(response => response.json())
            .then(data => {
                // dispatch({ type: 'LOADING', response: false });
                dispatch({ type: 'GET_COMPONENTS', response: data })
            })
            .catch(err => {
                // dispatch({ type: 'LOADING', response: false });
                dispatch({ type: 'GET_COMPONENTS', response: {} })
            });
    };
};

export default getComponents;