import React, { Component } from 'react';
import Container from '@material-ui/core/Container';

class PreviewContainer extends Component {

  
  componentDidMount(){
    // Get Access token
    this.getToken();
  } 

  componentDidUpdate(prevProps){
    if(prevProps.bundleId !== this.props.bundleId){
       this.getToken();
    }
  }
  
  getToken(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api/sf/onetimesftoken', requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data && 'token' in data) {
                this.setState({token:data.token});
                this.loadComp();
            }
        })
        .catch(err => {
            console.log(err);
        })
  }

  loadComp(){
      if(this.props.compName){
        const endpoint=process.env.REACT_APP_SALESFORCE_ENDPOINT;
        const bundleId = this.props.bundleId;
        window.$Lightning.use(process.env.REACT_APP_SALESFORCE_APP,()=>{ 
            let compName = "c:"+this.props.compName;
            var el = document.getElementById(bundleId);
            if(el && bundleId ){
              if(el){
              el.innerHTML="";
              }
              try{
                  window.$Lightning.createComponent(compName,{},bundleId,function(comp,status, errorMessage){
                  // this.props.match.params.load();
                  console.log("Component created");
                  
                  });
              }
              catch(err){
                  console.log(err);
              }   
          }
        },endpoint,this.state.token);
      }
  }

  render() {
    return (
      <Container component="main">
        <div className="CompPreview" id={this.props.bundleId}></div>
      </Container>
    )
  }
}

export default PreviewContainer
