import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './UpcomingEvent.scss';
import Loader from '../Loader/Loader';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';


const UpcomingEvent = () => {
    const [trainingDetails, setTrainingDetails] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify()
        };
        fetch('/api/sf/trainingcontent', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.hasError) {
                setTrainingDetails([]);
            } else {
                const details = data['data'];
                const locations = [...new Set(details.map(item => item.Location__c))];
                const recentEventDates = [];
                const recentEvents = [];
                locations.forEach(location => {
                    if(location) {
                        const dates = details.filter(detail => detail['Location__c'] === location && (new Date() - new Date(detail['Start_Date__c']) < 0))
                            .map(item =>  new Date() - new Date(item['Start_Date__c']));
                        recentEventDates.push({location: location, date: Math.min(...dates)});
                    }
                });
                recentEventDates.forEach(recentEvent => {
                    recentEvents.push(...(details.filter(detail => (detail['Location__c'] === recentEvent['location'])
                        &&  (Math.abs(new Date() - new Date(detail['Start_Date__c']) === recentEvent['date'])))));
                })
                setTrainingDetails(data['data']);
                setIsLoaded(true);
            }
        })
        .catch(err => {
            setTrainingDetails([]);
        });
    }, []);
    
    return (
        <div className="upcoming-event">
            <div className="upcoming-event-title sub-heading">
                Upcoming Trainings
                <Link className="calendar-link" to="/trainingCalendar">Events Calendar</Link>
            </div>
            <TableContainer>
                <Table className="upcoming-evets table" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left"><span className="text-small-5">Training Title</span></TableCell>
                        <TableCell align="center"><span className="text-small-5">Start Date</span></TableCell>
                        <TableCell align="center"><span className="text-small-5">End Date</span></TableCell>
                        <TableCell align="center"><span className="text-small-5">Location</span></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {trainingDetails.length > 0 && trainingDetails.map(training => 
                        <TableRow>
                            <TableCell align="left"><span className="text-small-2">{training.Title__c}</span></TableCell>
                            <TableCell align="center"><span className="text-small-2">{training.Start_Date__c}</span></TableCell>
                            <TableCell align="center"><span className="text-small-2">{training.End_Date__c}</span></TableCell>
                            <TableCell align="center"><span className="text-small-2">{training.Location__c}</span></TableCell>
                        </TableRow>
                    )}
                    {trainingDetails.length === 0 && !isLoaded && (
                    <TableRow>
                        <TableCell colSpan="4" className="text-center">Loading Training Details ...</TableCell>
                    </TableRow>
                    )}
                    {trainingDetails.length === 0 && isLoaded && (
                    <TableRow>
                        <TableCell colSpan="4" className="text-center">No Upcoming Trainings</TableCell>
                    </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default UpcomingEvent;
