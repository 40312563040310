import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Users.scss';
import Modal from 'react-bootstrap/Modal';
import SfFileupload from '../SfFileupload/SfFileupload';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

const TableReact = ({ tab, columns, data, total, getUsers, page, details, admin, approve }) => {

  return (
    <>
      <table className="mt-3">
        <thead>
          <tr className="text-level-7">
            {columns.map(headerGroup => {
              return (<th>
                {headerGroup.Header}
              </th>)
            })}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr className="text-level-8">
                {columns.map(property => {
                  return (<td>{row[property.accessor]}</td>)
                })
                }
                <td className="mx-auto action">
                  {/* {tab === 0 ?
                    <button className="btn-primary btn-black margin-right-8" onClick={() => {
                      admin(row);
                    }}>Make as Admin</button>: ''
                  } */}
                  {tab === 2 ?
                    <button className="btn-primary btn-black margin-right-8" onClick={() => {
                      approve(row);
                    }}>Approve Billing</button>: ''
                  }
                <button className="btn-primary btn-black" onClick={() => {
                  details(row);
                }}>Show Details</button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {Math.ceil(total / 20) > 1 ?
        <div className="pagination margin-top-24">
          <div className="">
            <button className="btn-primary btn-black" onClick={() => getUsers(0)} disabled={page === 0}>
              {'<<'}
            </button>{' '}
            <button className="btn-primary btn-black" onClick={() => getUsers(page - 1)} disabled={page === 0}>
              {'<'}
            </button>{' '}
            <button className="btn-primary btn-black" onClick={() => getUsers(page + 1)} disabled={Math.ceil(total / 20) === page + 1}>
              {'>'}
            </button>{' '}
            <button className="btn-primary btn-black" onClick={() => getUsers(Math.ceil(total / 20) - 1)} disabled={Math.ceil(total / 20) === page + 1}>
              {'>>'}
            </button>{' '}
          </div>
          <div>
            <span>
              Page{' '}
              <strong>
                {page + 1} of {Math.ceil(total / 20)}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                min="1"
                max={Math.ceil(total / 20)}
                defaultValue={page + 1}
                value={page + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  getUsers(page)
                }}
              />
            </span>{' '}
          </div>
        </div> : ""
      }
    </>
  )
}

const Users = ({ userData, login, dispatch }) => {
  const [userToken, setUserToken] = useState('');
  const [tabbedIndex, setTabbedIndex] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [search, setSearch] = useState('');
  const [field, setSearchField] = useState('');
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [fileUpload, setFileUpload] = React.useState('');
  const [filedata, setFiledata] = React.useState({});
  const token = login && login.token ? login.token : localStorage.getItem('usertoken');
  const [snackbaropen, setSnackbaropen] = React.useState(false);

  let body = {};

  const showDetails = (item) => {
    item.billing = false;
    item.admin = false;
    setModal(true);
    setUser(item);
  }

  const makeAdmin = (item) => {
    setModal(true);
    item.admin = true;
    setUser(item);
  }

  const approveBilling = (item) => {
    setModal(true);
    item.billing = true;
    setUser(item);
  }

  const uploadImage = (e) => {
    setFileUpload(e.target.files[0]);
  }

  const approveUser = (user) => {
    const data = new FormData();
    data.append('file', fileUpload);

    const requestOptions = {
      method: 'POST',
      body: data
    };

    fetch(`/api/approveUser?user=${user.id}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        getUsers();
      })
      .catch(err => {
        setLoading(false);
      });
  }

  const getUsers = (tablePage = 0) => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    let apiUrl;

    if (tabbedIndex === 0) {
      apiUrl = `/api/licensedusers?offset=${tablePage}&count=20`;
    } else if (tabbedIndex === 1) {
      apiUrl = `/api/standardusers?offset=${tablePage}&count=20`;
    } else {
      apiUrl = `/api/pendingApprovalUser?offset=${tablePage}&count=20`;
    }

    fetch(apiUrl)
      .then(response => {
        
        for (let [key, value] of response.headers) {
          if (key == 'content-range') {
            setTotal(value.split('/')[1]);
          }
        }
        return response.json();
      })
      .then(data => {
        setPage(tablePage);
        const resp = data.map((i) => {
          let obj = {};
          obj = i;
          obj.email = i.Email;
          obj.firstName = i.FirstName;
          obj.lastName = i.LastName;
          obj.id = i.Id;
          obj.projectName = i.ProjectName;
          obj.status = i.Status;
          obj.role = i.rolename;
          obj.licencedDate = i.BillingApprovedDate ? formatDate(i.BillingApprovedDate): '';
          obj.registeredDate = formatDate(i.CreatedDate);

          return obj;
        })
        setData(resp);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    getUsers();
  }, [tabbedIndex]);


  function getAura2LWCToken(toolId) {

    let assetname = '';
    if (toolId === 3) {
      assetname = process.env.REACT_APP_AN_AURA2LWC
    } else if (toolId === 6) {
      assetname = process.env.REACT_APP_AN_EVALUATOR
    }
    if (assetname && token) {
      let body = {
        "assetname": assetname,
        "token": token,
      };
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };
      fetch('/api/authenticate', requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data) {
            if (data) {
              const token = data.token;
              let baseLink = '';
              if (toolId === 3) {
                baseLink = process.env.REACT_APP_AURA2LWCLINK;
              } else if (toolId === 6) {
                baseLink = process.env.REACT_APP_EVALUATORLINK;
              } else if (toolId === 4) {
                baseLink = process.env.REACT_APP_AN_TEST_UI_BOT;
              } else if (toolId === 2) {
                baseLink = process.env.REACT_APP_AN_VALUATE;
              }
              if (baseLink) {
                const link = baseLink + "?token=" + token;
                console.log(link);
                var win = window.open(link, '_blank');
                win.focus();
              }
            }
          }
        })
        .catch(err => console.log(err));
    }
    if (toolId === 5) {
      const link = process.env.REACT_APP_DYNAMIC_UI_LINK;
      var win = window.open(link, '_blank');
      win.focus();
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Registered Date',
        accessor: 'registeredDate',
      },
    ],
    []
  )

  function onFileUpload(data){
    console.log(data);
    console.log(JSON.stringify(data));
    setFiledata(data);
  }

  function changeBilling(){
    let data ={
      userId: user.id,
      documentName: filedata.fileName,
      documentId: filedata.fileId,
      orgLink:filedata.orgLink
    };
    console.log(data);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',token: login },
        body: JSON.stringify(data)
      };
      fetch('/api/order/billingapproved', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLoading(false);
        console.log(data);
        setFiledata({});
        setModal(false);
        setSnackbaropen(true);
      })
      .catch(err => {
        console.log(err);
        if(err && 'message' in err){
          console.lof("error");
          console.log(err);
        }
        setLoading(false);
      });
  }

  const validate = values => {
    const errors = {};
    if (!values.amt) {
      errors.amt = 'Required';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      amt:'',      
    },
    enableReinitialize:true,
    validate,
    onSubmit: values => {
      console.log("on submit");
      console.log(values);
      console.log(filedata);
      let data ={
        userId: user.id,
        documentName: filedata.fileName,
        documentId: filedata.fileId,
        orgLink:filedata.orgLink
      }
      console.log(data);
      // changeBilling(data);
    },
  });

  const handleSnackClose = (event, reason) => {
    setSnackbaropen(false);
  }

  return (
    <Container component="main">
      <Loader show={loading} />
      <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success">
          License role added on user {user.firstName} {user.lastName}
        </Alert>
      </Snackbar>
      <Link to="/dashboard" className="back-button"><BackButton></BackButton><div>Back to Dashboard</div></Link>
      <div className="users margin-top-64">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <span className={`text-level-7 nav-link ${tabbedIndex === 0 ? 'active sub-heading' : ''}`} onClick={() => {
              setTabbedIndex(0);
            }}>Licensed Users</span>
          </li>
          <li className="nav-item">
            <span className={`text-level-7 nav-link ${tabbedIndex === 1 ? 'active sub-heading' : ''}`} onClick={() => {
              setTabbedIndex(1);
            }}>Standard Users</span>
          </li>
          <li className="nav-item">
            <span className={`text-level-7 nav-link ${tabbedIndex === 2 ? 'active sub-heading' : ''}`} onClick={() => {
              setTabbedIndex(2);
            }}>Pending License Approval</span>
          </li>
        </ul>
        {data && data.length > 0 ?
          <TableReact tab={tabbedIndex} columns={columns} data={data} total={total} getUsers={getUsers} page={page} details={showDetails} admin={makeAdmin} approve={approveBilling} /> : ''
        }
        <Modal className="project-modal" show={modal} centered={true} size="lg" animation={true}>
          <Modal.Header className="font-weight-bold text-level-7">{user.firstName} {user.lastName}</Modal.Header>
          {user.admin ? '' : <span className="close" onClick={() => {
                setModal(false);
                setFiledata({});
                setFileUpload('');
              }}>x</span>}
          
          <Modal.Body>
            {user.admin ?
              <div>Are you sure, you want to make {user.firstName} admin ?</div>
              : user.billing ?
                <div>
                  <Grid item xs={12} sm={12}>            
                  <SfFileupload onfileupload={onFileUpload} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  {fileUpload ?
                    <button className="btn-primary" onClick={() => {
                      approveUser(user);
                      setLoading(true);
                    }}>Approve</button> : ''
                  }
                  </Grid>
                  <Grid container>
                  <Grid item xs={12} sm={12} className="form-inp">
                    </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} sm={12} className="form-inp align-center">
                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                      className="form-inp"
                      disabled={!filedata.fileName}
                      onClick={()=>changeBilling()}
                      >
                      Update User
                    </Button>
                    
                  </Grid>
                  </Grid>
                </div> :
                <div>
                  <div className="text-level-8 mb-3">Email : {user.email}</div>
                  {user.registeredDate ?
                    <div className="text-level-8 mb-3">Registered Date : {user.registeredDate}</div> : ''
                  }
                  {user.projectName ?
                    <div className="text-level-8 mb-3">Project : {user.projectName}</div> : ''
                  }
                  {user.WBSCode ?
                    <div className="text-level-8 mb-3">WBS Code : {user.WBSCode}</div> : ''
                  }
                  {user.licencedDate ?
                    <div className="text-level-8 mb-3">Licensed Date : {user.licencedDate}</div> : ''
                  }
                  <div className="text-level-8 mb-3">Billing Approved : {user.IsBillingApproved ? 'Y': 'N'}</div>
                  <div className="text-level-8 mb-3">Leadership Approved : {user.IsLeadershipApproved ? 'Y': 'N'}</div>
                  <div className="text-level-8 mb-3">Registered for License Purchase : {user.RequestedForLicensePurchase ? 'Y': 'N'}</div>
                  {user.Status ?
                    <div className="text-level-8 mb-3">Status : {user.Status}</div> : ''
                  }
                  {user.USIGDMName ?
                    <div className="text-level-8 mb-3">USI GDM Name: {user.USIGDMName}</div> : ''
                  }
                  {user.USIGDMEmail ?
                    <div className="text-level-8 mb-3">USI GDM Email : {user.USIGDMEmail}</div> : ''
                  }
                  {user.USIPPMDName ?
                    <div className="text-level-8 mb-3">USI PPMD : {user.USIPPMDName}</div> : ''
                  }
                  {user.USIGDMName ?
                    <div className="text-level-8 mb-3">US GDM Name : {user.USSMName}</div> : ''
                  }
                  {user.USSMEmail ?
                    <div className="text-level-8 mb-3">US GDM Email : {user.USSMEmail}</div> : ''
                  }
                </div>
            }
          </Modal.Body>
          {user.admin ? 
            <Modal.Footer>{
              <div>
                <button className="btn-secondary margin-right-24" onClick={() => {
                  setModal(false);
                }}>Cancel</button>
                <button className="btn-primary" onClick={() => {
                  setModal(false);
                }}>Confirm</button>
              </div>
            }
          </Modal.Footer>: ''}
        </Modal>
      </div>
    </Container>
  );
};

Users.propTypes = {
  login: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    login: state.login.login
  }
}

export default connect(mapStateToProps)(Users);