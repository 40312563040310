import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Rating from "@material-ui/lab/Rating";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import CardMedia from '@material-ui/core/CardMedia';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getDashboard, getMonitizedFlag } from '../../actions/dashboard';
import './Dashboard.scss';
import * as Download from './../../assets/icons/shape.png';
import RecentActivities from '../RecentActivities/RecentActivities';
import UpcomingEvent from '../UpcomingEvent/UpcomingEvent';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import TUNDERICON from './../../assets/images/salesforcetunder.png';
import jwtDecode from 'jwt-decode';
import { Tooltip } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../TabPanel/TabPanel';
import { CardHeader } from '@material-ui/core';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    dashboardTitle: {
        textAlign: 'left',
        margin: theme.spacing(2),
    },
    reportdashboardTitle: {
        textAlign: 'left',
    },
    reportSummary: {
        width: '100%',
        margin: theme.spacing(2),
        border: '1px solid #D3D8E8',
        borderRadius: '6px',
        background:'#fff',
        boxShadow: '0 2px rgba(0,0,0,0.19)',
    },
    summary: {
        width: '100%',
        margin: theme.spacing(2, 2, 0, 2),
        border: '1px solid #D3D8E8',
        borderRadius: '6px',
        background:'#fff',
        boxShadow: '0 2px rgba(0,0,0,0.19)',
    },
    summaryTitle: {
        height: '100px',
        padding: theme.spacing(2),
        backgroundColor: '#3b5998',
        backgroundImage: 'linear-gradient(#4e69a2, #3b5998 50%)',
        borderBottom: '1px solid #133783',
        color: '#fff',
        fontSize: 18,
        position: 'relative',

    },
    summaryDesc: {
        display: 'flex',
        flexDirection: 'row',
        top: '64px',
        color: '#000',
        alignItems:'baseline',
    },
    licence: {
        width: '33%',
        margin: theme.spacing(2),
        alignItems: 'center',
        textAlign: 'center',
        margin: 'auto'
    },
    component: {
        width: '33%',
        margin: theme.spacing(2),
        alignItems: 'center',
        textAlign: 'center',
        margin: 'auto'
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1)
    },
    image: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 9,
        height: '80px',
        width: '80px'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        paddingBottom: 0
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        width: 'calc(100% - 120px)'
    },
    pos: {
        margin: theme.spacing(1.5, 0),
        display: 'flex',
        flexDirection: 'column'
    },
    cardAction: {
        justifyContent: 'space-around',
        padding: theme.spacing(1),
        textDecoration: 'none'
    },
    cardActionReport: {
        display: 'flex',
        margin: 0,
        textDecoration: 'none'
    },
    anchor: {
        textDecoration: 'none'
    },
    cardActionReportDownload: {
        margin: 'auto',
        color: '#3b5998',
        background: 'transparent'
    },
    reports: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    accountButtons: {
        textAlign: 'center',
        borderRadius: '.1875rem',
        display: 'inline-block',
        border: 0,
        fontWeight: 600,
        backgroundColor: '#3b5998',
        color: '#fff',
        padding: theme.spacing(2),
    },
    downloadInfo: {
        display: 'block',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'right',
        color: '#2e8e02'
    },
    reportInfo: {
        display: 'block',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'right',
        color: '#e0803d'
    },
    noBorder: {
        border: 'none'
    }
}));

const Dashboard = ({ dashboardData, login, loading, dispatch, role, monitizationFlag }) => {
    const [userPoints, setUserPoints] = useState(0);
    const [reportData, setReportData] = useState([]);
    const [totalUserData, setTotalUseData] = useState(0);
    const [documentDownloadCount, setDocumentDownloadCount] = useState({});
    const token = login && login ? login : localStorage.getItem('usertoken');
    const history = useHistory();
    const [showReedem, setShowReedem] = useState(false);
    const pointsAccIntro1 = 'Awesome you have';
    const pointsAccIntro2 = 'points. You can use these points to download components and keep accruing points by contributing to reusable component repository.';
    let body = {};

    React.useEffect(() => {
        dispatch(getDashboard(token));
        dispatch(getMonitizedFlag());
        if(dashboardData) {
            const start = new Date();
            start.setFullYear(start.getFullYear() - 1);
            const end = new Date();
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                },
                body: JSON.stringify({
                    "isDashboard": true,
                    "project": dashboardData.project,
                    "startDate":start.toISOString(),
                    "endDate": end.toISOString()
                })
            };
            fetch('/api/admin/report/userdetails', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const userDetailsData = [...data.data];
                        const userNumber = userDetailsData.reduce((item1, item2) => Number(item1.count) + Number(item2.count) );
                        setTotalUseData(userNumber);
                    }
            });
            fetch('/api/report/projecttoolanalysis', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const reports = [...data.data.report];
                        const reportData = reports.map(function (item) {
                            delete item.children;
                            return item;
                        });
                        setReportData(reportData);
                    }
                });
            getPoints();
        }
        if (monitizationFlag === 'true') {
            setShowReedem(true);
        }
        getDocumentDownloadCount();
    }, [dashboardData && dashboardData.length > 0, monitizationFlag]);

    function downloadPlaybook(event, playbookName, assetName) {
        event.stopPropagation();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({
                "Name": playbookName,
                "assetname":assetName
            })
        };
        fetch('/api/analytics/report/trackdownload', requestOptions)
        .then(response => response.json())
        .then(data => {
           console.log(data);
        });
    };


    function trackDownloadDocuments(event, file) {
        event.stopPropagation();
        const name = file.replace(file.substring(file.indexOf('.')),'');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({
                "name": name,
                "filename":file
            })
        };
        fetch('/api/analytics/report/trackdocdownload', requestOptions)
        .then(response => response.json())
        .then(data => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                },
                body: JSON.stringify({
                    "name": name,
                    "filename": file
                })
            };
                fetch('/api/analytics/report/getdocdownloadcount', requestOptions)
                .then(response => response.json())
                .then(data => {
                    documentDownloadCount[file] = data.data.downloads;
                    setDocumentDownloadCount(documentDownloadCount);
                });
        });
    };


    function downloadDoc(event, path,name){
        axios({
            url: path,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });

          trackDownloadDocuments(event, name);
    }

    function getDocumentDownloadCount() {
        const filenames = {
            "LightningBasics.zip":0,
            "LightningAdvanced.zip":0,
            "LightningAura.zip":0,
            "LightningDesignSystems.zip":0,
            "LightningBasics.zip":0,
            "Salesforce_Lightning_Estimation_Model_v1.xlsx":0,
            "Salesforce_LWC_Implementations_Point_Of_View_v1.pdf":0,
            "LightningBestPractices_v2.pdf":0
        }
        Object.keys(filenames).forEach(function (file) {
            let name = file.replace(file.substring(file.indexOf('.')),'');
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                },
                body: JSON.stringify({
                    "name": name,
                    "filename": file
                })
            };
                fetch('/api/analytics/report/getdocdownloadcount', requestOptions)
                .then(response => response.json())
                .then(data => {
                    filenames[file] = data.data.downloads;
                });
        });
        setDocumentDownloadCount(filenames);
    }

    function getAura2LWCToken(toolId, event) {
        event.stopPropagation();
        console.log(toolId);
        let assetname = '';
        if (toolId === 3) {
            assetname = process.env.REACT_APP_AN_AURA2LWC;
        } else if (toolId === 6) {
            assetname = process.env.REACT_APP_AN_EVALUATOR;
        } else if(toolId === 2){
            assetname = process.env.REACT_APP_AN_VALUATE;
        } else if(toolId === 4){
            assetname = process.env.REACT_APP_AN_TESTUIBOT;
        }

        if (assetname && token) {
            let body = {
                "assetname": assetname,
                "token": token,
            };
            // const [userToken, setUserToken] = useState('');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            fetch('/api/authenticate', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        if (data) {
                            const token = data.token;
                            let baseLink = '';
                            if (toolId === 3) {
                                baseLink = process.env.REACT_APP_AURA2LWCLINK;
                            } else if (toolId === 6) {
                                baseLink = process.env.REACT_APP_EVALUATORLINK;
                            } else if(toolId === 4){
                                baseLink = process.env.REACT_APP_TEST_UI_BOTLINK;
                            } else if(toolId === 2){
                                baseLink = process.env.REACT_APP_VALUATELINK;
                            }
                            console.log(baseLink);
                            if (baseLink) {
                                const link = baseLink + "?token=" + token;
                                console.log(link);
                                var win = window.open(link, '_self');
                                win.focus();
                            }
                        }
                    }
                })
                .catch(err => console.log(err));
        }
        if (toolId === 5) {
            const link = process.env.REACT_APP_DYNAMIC_UILINK;
            var win = window.open(link, '_blank');
            win.focus();
        }
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }

    function formatDateTime(date){
        console.log(date);
        if(date){
            let d = new Date(date);
            return new Intl.DateTimeFormat('default', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(d);
        }
        return '';
    }

    function getTitleLetters(name){
        if(name){
            let initials='';
            const arr = name.split(" ");
            arr.forEach(word => {
                if(word){
                    initials=initials+word[0];
                }
            });
            return initials.length>2 ? initials.slice(0,2).toLocaleLowerCase(): initials.toLocaleLowerCase();
        }
        return '';
    }

    function getReportData(name) {
        let reportDataItem = '0';
        reportData.forEach((item, index) => {
            if(item['AssetName'] === name) {
                reportDataItem = item['count'];
            }
        });
        return reportDataItem;
    }

    function openSideNavItem(e) {
        let el = document.querySelector('#hamburger');
        let selected = e.target.classList[3];
        if(el) {
            el.click();
            setTimeout(() => {
                let expansionEl = document.querySelector('.' + selected + ' .expansion-panel .MuiButtonBase-root');
                expansionEl.click();
            }, 1500)
        }
    }

    function getPoints() {
        const userDetails = jwtDecode(login);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({ 'email': userDetails.email}),
        };
        fetch('/api/sf/getpoints', requestOptions)
            .then(response => response.json())
            .then(data =>
                {
                    if (data.data.length)
                        setUserPoints(data.data[0].Available_Points__c);
                });
    }

    function tabProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const download = (path,name) => {
        axios({
            url: path,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });
    }

    const classes = useStyles();

    return (
        dashboardData && dashboardData.user ?
            <Container component="main" className="dashboard-page">
                <Loader show={loading} />
                <div className={classes.paper}>
                        <div className="panel-container-1">
                            <div className="panel-heading report-summary-title">
                                <Grid container spacing={2} className="">
                                        <Grid item xs={6}>
                                            <h1 className="justify-center sub-heading main-cozone-heading" style={{'color': '#86BC25'}}>
                                                Home
                                            </h1>
                                        </Grid>
                                        <Grid item xs={6} className="panel-right-text">
                                                # of registered users: {totalUserData.count}
                                        </Grid>

                                </Grid>

                            </div>
                            <div className="inner-panel text-small-2 py-3 px-4">
                            Salesforce CoZone is Deloitte's Salesforce Lightning marketplace to host Lightning Assets developed in-house. It also holds Lightning best practices, Point Of Views and reusable LWC, Aura & Salesforce provided OOTB components.
                            </div>
                            <div className="user-details">

                            </div>
                        </div>
                    <div className={classes.summary}>
                        <div className="report-summary-title sub-heading">
                            <span>Account Summary</span>
                            {role && role==='admin' &&<span className="float-right">
                                <Link to='/admin/reports'>Reports</Link>
                            </span>}
                            {role && role==='licensed' &&<span className="float-right">
                                <Link to='/user/reports'>My Reports</Link>
                            </span>}
                        </div>
                        <div className={classes.summaryDesc}>
                            <div className="login">
                                <span className="text-level-7">Welcome, {dashboardData.user}</span>
                                {dashboardData.lastLoginDate && dashboardData.lastLoginDate!='' && <span className="login-date text-level-9 text-grey">
                                    Last Loggedin: {formatDateTime(dashboardData.lastLoginDate)}
                                </span>}

                            </div>
                            {dashboardData.hasLicense &&
                                <div className="licence">
                                    {dashboardData.project && <div className="project-name text-level-7 text-grey">Project: {dashboardData.project}</div>}
                                    {showReedem && <span className="text text-level-9 text-grey">License Purchase Date: {formatDateTime(dashboardData.licensePurchaseDate)}</span> }
                                </div>
                            }
                            {!dashboardData.hasLicense &&
                                <div className="licence">
                                    {!dashboardData.RequestedForLicensePurchase && <span className="text-level-7 text-center">Provide project details to purchase license</span>}
                                    { dashboardData.user && dashboardData.RequestedForLicensePurchase?(<span className="text-level-7">License Requested</span>):
                                        (<Link to='/purchaselicense' className="btn-primary licence-purchase text-grey">Purchase License</Link>)}
                                </div>
                            }
                            {dashboardData.noOfComponentsSubmitted &&
                                <div className="login last">
                                {
                                    showReedem && <span>
                                        <Tooltip title = {pointsAccIntro1 + ' ' + userPoints + ' ' + pointsAccIntro2}>
                                            <span className="text-level-7 points">
                                                Points Accrued:<b>&nbsp;{userPoints}</b>
                                                <EmojiEventsIcon className="point-icon"/>
                                            </span>
                                        </Tooltip>
                                    </span>
                                }
                                    <Link to={`/submitComponent`} className="btn-primary margin-top-4">Submit Component</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.reportSummary}>
                    <Tabs className="report-summary-title sub-heading" value={value} onChange={handleTabChange} aria-label="Dashboard Tabs">
                        <Tab label="Lightning Suite" {...tabProps(0)} />
                        <Tab label="Reusable Components" {...tabProps(1)} />
                        <Tab label="Lightning Documents" {...tabProps(2)} />
                        <Tab label="Trainings & Events" {...tabProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0} className="lightining-suite">
                        <div className="grid tool-cards">
                            {
                                (dashboardData.tools || []).map((item, index) => {
                                    const docLink = `${process.env.PUBLIC_URL}/media/${item.docLink}`;
                                    return (
                                        <div className="dashboard-card grid-item" onClick={()=>history.push(`/details?toolId=${item.Id}`)} key={item.Id}>
                                        <div className="card-content">
                                            <div className="dashboardcard-header">
                                                    <div className="text-level-7 title">{item.name}</div>
                                            </div>
                                            <div className="stats">
                                                {(item.name!=='Lightning Dynamic UI (Aura)')?
                                                <div className="reports">
                                                    <span className="tex-hint text text-small-3">USAGE</span>
                                                    <span className="value text-bold text-level-11">{getReportData(item.name)}</span>
                                                </div>
                                                :
                                                ''
                                                }
                                            <div className="reports">
                                                <Rating name="half-rating-read" defaultValue={item.rating} precision={0.1} readOnly size="small"/>
                                                <span className="tex-hint text text-small-3 rating-text">{item.rating?Number(item.rating).toString():0} out of 5 from {item.ratingcount?item.ratingcount:0} rating(s)</span>
                                                </div>

                                            </div>
                                            </div>
                                            <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                        <a href={docLink} target="_blank" className="download" onClick={(event)=> downloadPlaybook(event,item.docLink, item.name)}>
                                                            <span className={classes.download}>Download Playbook</span>
                                                            <img className="icon" src={Download} />
                                                        </a>
                                                        {item.playbookcount && <div className="playbook-download text text-small-3">{item.playbookcount}&nbsp;downloads</div>}
                                                    </div>
                                                    <div className="launch">
                                                        {
                                                            item.Id != 1 && dashboardData.hasLicense && role && (role==='admin' || role==='licensed') && <a onClick={(event) => getAura2LWCToken(item.Id, event)} className="btn-primary">
                                                                <span className={classes.download}>Launch</span>
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                    )
                                })
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} className="lightning-components">
                        <div className="grid tool-cards">
                            <div className="dashboard-card grid-item cust-height lightning-components-aura" style={{'font-weight': 'bold', 'color': '#706E6B' }} onClick={openSideNavItem}>
                                Lightning Components (Aura)
                            </div>
                            <div className="dashboard-card grid-item cust-height lightning-components-web" style={{'font-weight': 'bold', 'color': '#706E6B' }} onClick={openSideNavItem}>
                                Lightning Web Components
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2} className="lightning-documents">
                        <div className="grid tool-cards">
                            <div className="dashboard-card grid-item cust-height">
                                        <div className="slds-grid  slds-p-bottom--small">
                                            <div className="slds-col text-level-8">LWC Basics</div>
                                            </div>
                                            <div className="slds-grid">
                                            <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/LightningBasics.zip","LightningBasics.zip")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['LightningBasics.zip'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>

                                        </div>
                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                <div className="slds-grid slds-p-bottom--small">
                                    <div className="slds-col text-level-8">Lightning Advanced</div>
                                    </div>
                                    <div className="slds-grid">
                                    <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/LightningAdvanced.zip","LightningAdvanced.zip")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['LightningAdvanced.zip'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>

                                </div>
                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                    <div className="slds-grid  slds-p-bottom--small">
                                    <div className="slds-col text-level-8">Aura</div>
                                    </div>

                                    <div className="slds-grid">
                                    <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/LightningAura.zip","LightningAura.zip")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['LightningAura.zip'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>

                                </div>

                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                    <div className="slds-grid  slds-p-bottom--small">
                                    <div className="slds-col text-level-8">Lightning Design Systems</div>
                                    </div>
                                    <div className="slds-grid">
                                    <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/LightningDesignSystems.zip","LightningDesignSystems.zip")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['LightningDesignSystems.zip'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>
                            </div>
                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                    <div className="slds-grid  slds-p-bottom--small">
                                    <div className="slds-col text-level-8">Lightning Basics</div>
                                    </div>
                                    <div className="slds-grid">
                                    <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/LightningBasics.zip","LightningBasics.zip")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['LightningBasics.zip'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>
                            </div>
                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                    <div className="slds-grid  slds-p-bottom--small">
                                    <div className="slds-col text-level-8">Estimation Model</div>
                                    </div>
                                    <div className="slds-grid">
                                    <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/Salesforce_Lightning_Estimation_Model_v1.xlsx","Salesforce_Lightning_Estimation_Model_v1.xlsx")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['Salesforce_Lightning_Estimation_Model_v1.xlsx'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>

                                </div>
                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                    <div className="slds-grid">
                                    <div className="slds-col text-level-8">LWC Implementation - Point of View </div>
                                    </div>
                                    <div className="slds-grid">
                                    <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event,"/docs/Salesforce_LWC_Implementations_Point_Of_View_v1.pdf","Salesforce_LWC_Implementations_Point_Of_View_v1.pdf")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['Salesforce_LWC_Implementations_Point_Of_View_v1.pdf'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>

                                </div>
                            </div>
                            <div className="dashboard-card grid-item cust-height">
                                <div className="slds-grid">
                                    <div className="slds-col text-level-8">Best Practices Document</div>
                                </div>
                                <div className="slds-grid">
                                <div className="dashboardcard-footer">
                                                <div className="actions">
                                                    <div className="view">
                                                    <div className="download">
                                                    <Link style={{"font-size": "12px"}} to={"#"} onClick={(event)=>downloadDoc(event, "/docs/LightningBestPractices_v2.pdf","LightningBestPractices_v2.pdf")}>Download Document</Link>

                                                        <img className="icon" src={Download} />
                                                        </div>
                                                        <div className="playbook-download text text-small-3">{documentDownloadCount['LightningBestPractices_v2.pdf'] + ' Downloads'}
                                                    </div>
                                                </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={3} className="trainings-events">
                    <div className="grid tool-cards">
                     <UpcomingEvent />
                        </div>
                    </TabPanel>
                </div>
            </Container> : ''
    );
};

Dashboard.propTypes = {
    login: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        dashboardData: state.dashboard.dashboard,
        login: state.login.login,
        role: state.login.role,
        monitizationFlag: state.dashboard.monitizationFlag
    }
}

export default connect(mapStateToProps)(Dashboard);
