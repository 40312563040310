import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import LightningLogo from '../../assets/images/lightning_cozone.png';
import Grid from '@material-ui/core/Grid';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          CoZone
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

function ResetPassword(props) {
    const classes = useStyles();
    const hashId = new URLSearchParams(props.location.search).get('id'); 
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const history = useHistory();

    const validate = values => {
      const errors = {};
      if (!values.new_password) {
        errors.new_password = 'Required';
      } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.new_password)) {
          /*Password must be of 6 to 16 digits with atleast one number and atleast a special character*/
        errors.new_password = 'Invalid Password';
      }
      if (!values.cnf_password) {
        errors.cnf_password = 'Required';
      } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.cnf_password)) {
          /*Password must be of 6 to 16 digits with atleast one number and atleast a special character*/
        errors.cnf_password = 'Invalid Password';
      }
      return errors;
  }

  function handleReset(values) {
      const value = Object.assign({},values,{
          resetHash: hashId
      });
      console.log(values);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(value)
      };
      fetch('/api/resetpassword', requestOptions)
      .then(response => response.json())
      .then(data => {
          console.log(data);
          setLoading(false);
          if (data.success) {
            setError('');
            setOpen(true);
          }else if(!data.success && data.message){
            setError(data.message);
          }
      })
      .catch(err => {
          console.log(err);
          setLoading(false);
          if(err.message){
            setError(err.message);
          }
          setError(err);
      });
  }

  const handleCloseAndRedirectToLogin = () => {
    setOpen(false);
    history.push("/");
  };

  

    const formik = useFormik({
      initialValues: {
          new_password: '',
          cnf_password: '',
      },
      validate,
      onSubmit: values => {
        handleReset(values);
        // alert(JSON.stringify(values, null, 2))
      },
  });

    return (
      <Container component="main" maxWidth="xs">
      <Loader show={loading} />
      <Dialog
        open={open}
        onClose={handleCloseAndRedirectToLogin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Password Reset"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Password reset successfull !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAndRedirectToLogin} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.paper}>
        <Grid container justify="center">
          <div className={classes.deloitteAvatar}>
            <img src={LightningLogo} height="120"/>
          </div>
        </Grid>
        <Typography component="subtitle" variant="p">
          Set A New Password
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="new_password"
                        label="New Password"
                        type="password"
                        id="new_password"
                        size="small"
                        required
                        onChange={formik.handleChange}                        
                        value={formik.values.new_password}
                        error={Boolean(formik.touched.new_password && formik.errors.new_password)}
                        helperText={(formik.touched.new_password && formik.errors.new_password) && formik.errors.new_password}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="cnf_password"
                        label="Confirm New Password"
                        type="password"
                        id="cnf_password"
                        size="small"
                        required
                        onChange={formik.handleChange}                        
                        value={formik.values.cnf_password}
                        error={Boolean(formik.touched.cnf_password && formik.errors.cnf_password)}
                        helperText={(formik.touched.cnf_password && formik.errors.cnf_password) && formik.errors.cnf_password}
                      />
            {error && <Typography component="h3" variant="body1" style={{'textAlign':'center','margin':'10px 0'}}>
            <span className="text-small-2" style={{color:'#da291c'}}>{error}</span>
            </Typography>}          
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Set New Password
            </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    )
}

export default ResetPassword;