import React, { useState, useEffect,Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { Link, useHistory } from 'react-router-dom';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader/Loader";
import CardMedia from "@material-ui/core/CardMedia";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./Feedback.scss";
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';

const useStyles = makeStyles((theme) => ({
table: {
  minWidth: 650,
},
paper: {
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
},
dashboardTitle: {
  textAlign: "left",
  margin: theme.spacing(2),
},
reportdashboardTitle: {
  textAlign: "left",
},
reportSummary: {
  width: "100%",
  margin: theme.spacing(2),
  border: "1px solid #3b5998",
},
reportSummaryTitle: {
  padding: theme.spacing(2),
  backgroundColor: "#3b5998",
  backgroundImage: "linear-gradient(#4e69a2, #3b5998 50%)",
  borderBottom: "1px solid #133783",
  color: "#fff",
  fontSize: 18,
  position: "relative",
},
summary: {
  width: "100%",
  margin: theme.spacing(2),
  border: "1px solid #3b5998",
},
summaryTitle: {
  height: "100px",
  padding: theme.spacing(2),
  backgroundColor: "#3b5998",
  backgroundImage: "linear-gradient(#4e69a2, #3b5998 50%)",
  borderBottom: "1px solid #133783",
  color: "#fff",
  fontSize: 18,
  position: "relative",
},
summaryDesc: {
  width: "100%",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "64px",
  background: "#fff",
  color: "#000",
  border: "1px solid rgba(0,0,0,0.24)",
},
login: {
  width: "33%",
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(2),
  alignItems: "center",
},
loginDate: {
  fontSize: "12px",
},
licence: {
  width: "33%",
  margin: theme.spacing(2),
  alignItems: "center",
  textAlign: "center",
  margin: "auto",
},
component: {
  width: "33%",
  margin: theme.spacing(2),
  alignItems: "center",
  textAlign: "center",
  margin: "auto",
},
grid: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
  flexWrap: "wrap",
  marginTop: theme.spacing(1),
},
card: {
  width: "30%",
  margin: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "0 0.5rem 1rem 0 #E7E9F2",
  position: "relative",
},
image: {
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 9,
  height: "80px",
  width: "80px",
},
cardContent: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
  margin: theme.spacing(1, 1, 0),
  paddingBottom: 0,
},
form: {
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
},
submit: {
  margin: theme.spacing(3, 0, 2),
},
bullet: {
  display: "inline-block",
  margin: "0 2px",
  transform: "scale(0.8)",
},
title: {
  fontSize: 18,
  fontWeight: "bold",
  width: "calc(100% - 120px)",
},
pos: {
  margin: theme.spacing(1.5, 0),
  display: "flex",
  flexDirection: "column",
},
cardAction: {
  justifyContent: "space-around",
  padding: theme.spacing(1),
  textDecoration: "none",
},
cardActionReport: {
  display: "flex",
  margin: 0,
  textDecoration: "none",
},
anchor: {
  textDecoration: "none",
},
cardActionReportDownload: {
  margin: "auto",
  color: "#3b5998",
  background: "transparent",
},
reports: {
  display: "flex",
  justifyContent: "space-between",
},
download: {
  color: "#3b5998",
  background: "transparent",
},
accountButtons: {
  textAlign: "center",
  borderRadius: ".1875rem",
  display: "inline-block",
  border: 0,
  fontWeight: 600,
  backgroundColor: "#3b5998",
  color: "#fff",
  padding: theme.spacing(2),
},
textpadding: {
  marginLeft: 10,
},
}));

const Feedback = ({ login,loading }) => {

const classes = useStyles();

const token =
  login && login.token ? login.token : localStorage.getItem("usertoken");
console.log(token);
//const { id } = useParams();
//console.log('Id:'+id);

//const { id } = useParams();
//console.log('Id:'+id);

console.log('Id:'+window.location.search);
const params = new URLSearchParams(window.location.search);
const id = params.get('toolId'); // bar
const compoName = params.get('component'); // bar
console.log('ID :'+id);
console.log('compoName :'+compoName);
const history = useHistory();
const sfid = params.get('sfid');

var tools =  [{
  Id: '1',
  name: "Salesforce CoZone",
  lasttitleName: "Alphabetical List Display",
  imgLink: "Salesforce_Cozone.png",
  docLink: "Salesforce_CoZone_Playbook.pdf"
},
{
  Id: '3',
  name: "Aura2LWC",
  lasttitleName: "",
  imgLink: "AURA2LWC1.png",
  docLink: "Aura2Lwc_Playbook.pdf"
},
{
  Id: '2',
  name: "Lightning Valuate",
  lasttitleName: "",
  imgLink: "LightningValuate1.png",
  docLink: "Lightining_Valuate_Playbook.pdf"
},
{
  Id: '4',
  name: "Test Bot",
  lasttitleName: "",
  imgLink: "AURA2LWC.png",
  docLink: "Lightining_UI_test_bot_Playbook.pdf"
},
{
  Id: '5',
  name: "Lightning Dynamic UI (Aura)",
  lasttitleName: "",
  imgLink: "LDinterface1.png",
  docLink: "Dynamic_Lightning_UI_Playbook.pdf"
},
{
  Id: '6',
  name: "Lightning Evaluator",
  lasttitleName: "",
  imgLink: "LIGHTNINGEVALUATOR1.png",
  docLink: "Lightning_Evaluator_Playbook.pdf"
},
];
let compName = '';
tools.forEach((item) => {console.log(JSON.stringify(item));if(item.Id===id){compName=item.name}});
console.log('cmpName:'+compName);
const [questions, setQuestions] = useState([{options:[]}]);
const [starRating, setStarRating] = useState(0);
const [comments, setComments] = useState('');


//const[radio,setRadio]=useState('Agree');



const handleChange=(e,question,i)=>{
  const values = [...questions];
    values[i].value=e.target.value;
    /* questionsTemp.forEach(element=>{
      if(element.Id===id){

      element.value=e.target.value;
      }
  });*/
  setQuestions(values);
  console.log('questions:'+JSON.stringify(questions));

  // e.preventDefault();

};

const handleTextChange=(e)=>{
  setComments(e.target.value);
  console.log('text:'+comments);
  console.log('text:'+e.target.value);
};

const handleSubmit=(event)=>{
console.log('Questions Data:'+JSON.stringify([...questions]));
console.log('Rating:'+starRating);
console.log('Comments:'+comments);
var isValidated=false;
const values=[...questions];
values.forEach(element=>{
  if(!element.value||element.value===undefined||element.value===''){

    isValidated=true;
  }
});
if(isValidated===true){
  alert('Please answer all the questions on the Page');
}
if(!starRating||starRating===undefined||starRating===''){
  alert('Please rate the tool');
  isValidated=true;
}
if(isValidated===false){
  var reqBody=new Map();
  reqBody.set('Rating',starRating);
  values.forEach(element=>{reqBody.set(element.Question,element.value)});
  reqBody.set('comments',comments);
  let obj = {};
  reqBody.forEach(function(value, key){
    obj[key] = value
});
if(id==null||id==undefined||id==''){
  compName=compoName;
}
  const requestOptions2 = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'token':token,
      'Feedback':JSON.stringify(obj),
      'ComponentName':compName,
      'Rating':starRating
    })


  };
fetch('/api/feedback/survey/create', requestOptions2)
  .then(response => response.json())
  .then(items => {

    if (items) {
      if(items.success==='true'){
        const newValues=[...questions];
        newValues.forEach(element=>{element.value=''});
        setQuestions(newValues);
        setStarRating(0);
        setComments('');
        alert('ThankYou! Your Response submitted successfuly')
        if(id){
          // history.push('/details?toolId='+id);
          history.push('/dashboard');
        }
        else{
          history.push('/component/'+sfid);
        }
      }
    }


  })
  .catch(err => console.log(err));
}
event.preventDefault();
};

function fetchQuestions() {
const requestOptions = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' }

};
var questionsUrl='/api/feedback/questions/fetch/';
if(id==undefined||id==null||id==''){
  questionsUrl+='Component';
}
else
questionsUrl+='Tool';
fetch(questionsUrl, requestOptions)
  .then(response => response.json())
  .then(data => {

    if (data) {
      console.log('questions' + JSON.stringify(data));
      //setQuestions(data);

  var optionReqBody=[];
  data.forEach(element=>{optionReqBody.push(element.Id);
  console.log('optionReqBody:'+optionReqBody);
  /* if(id==undefined||id==null||id==''){
  element.Question=compoName+' '+element.Question;
  }
  else
  element.Question=compName+' '+element.Question;
  */
});

  const requestOptions2 = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'questionIds': optionReqBody
    })

  };
fetch('/api/feedback/options/fetch', requestOptions2)
  .then(response => response.json())
  .then(items => {

    if (items) {
      console.log('options:' + JSON.stringify(items));
      data.forEach(element=>{
      items.forEach(item=>{
        if(item.Question===element.Id){
          if(element.options)
          element.options.push(item);
          else{
          element.options=[];
          element.options.push(item);
        }
        }
      });
    });
    }
    setQuestions(data);

  })
  .catch(err => console.log(err));

    }

  })
  .catch(err => console.log(err));

  };

React.useEffect(() => {
  console.log('UseEffect');
  fetchQuestions();

}, []);
return (
  <form onSubmit={handleSubmit}>
  <Container component="main" className="feedback-page">
    <Loader show={loading} />
    <div className={classes.paper}>
      <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
      <h2 className="text-level-5 dashboard-title">Feedback Survey</h2>
      {questions.map((quetion,i) => (
          <Fragment key={`${quetion}~${i}`}>
        <div className={classes.summary}>
          <div className="report-summary-title sub-heading">{quetion.Question}</div>
          {(quetion.OptionsType==='Radio')?<div>
            <div className="text-level-8 options-title">
              <span>Choose one from below options:</span>
            </div>
            <FormControl component="fieldset">
              <RadioGroup aria-label="feedback" key={i} name="feedback" value={quetion.value} row onChange={(e)=>handleChange(e,quetion.Question,i)}>
                <div className="feedback-radio-group">
                {quetion.options.map((option,j) => (
                  <Fragment key={`${option}~${j}`}>
                  <FormControlLabel className="text-level-8"
                    key={j}
                    labelPlacement="top"
                    value={option.OptionName}
                    control={<Radio />}
                    label={option.OptionName}
                  />
                  </Fragment>
                ))}
                </div>
              </RadioGroup>
            </FormControl>
          </div>:
          <div >
            <div className="text-level-8 options-title">
              <span>Please type your answer:</span>
            </div>
            <div className="input-feedback">
               <TextField
                 variant="outlined"
                  fullWidth
                  size="small"
                  value={quetion.value}
                  onChange={(e)=>handleChange(e,quetion.Question,i)}
                 />
                 </div>
          </div>
          }
        </div>
        </Fragment>
      ))}

      <div className={classes.summary}>
        <div className="report-summary-title sub-heading">{id?'Provide suggestions for feature enhancements or details of other products which provide similar capabilities(If any)':'Please Provide the feedback or Additional comments(if any)'} :</div>
        <div>
          <div className="text-level-8 options-title">
            <span>Please enter your comments below:</span>
          </div>
          <div className="options-title">
            <textarea rows={3} cols={100} value={comments} onChange={handleTextChange}/>
          </div>
        </div>
      </div>
      <div className={classes.summary}>
        <div className="report-summary-title sub-heading">{id?'Provide rate the Tool':'Please rate the Component'} :</div>
        <div>
          <div className="text-level-8 options-title">
            <span>Please rate below on the scale from 1 to 5:</span>
          </div>
          <div className="options-title">
            <Rating name="simple-controlled" size="large" value={starRating} onChange={(event, newValue) => {setStarRating(newValue);console.log('Rating:'+newValue)}}/>
          </div>
        </div>
      </div>


      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
            <div>
            <input type="submit" value="Submit" className="btn-primary" />
            </div>

        </Grid>
      </div>
      <br/>
      <br/>

    </div>
  </Container>
  </form>
);
};

Feedback.propTypes = {
login: PropTypes.object.isRequired,
loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
return {
  login: state.login.login,
  loading: state.loading,

};
}

export default connect(mapStateToProps)(Feedback);
