import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { Container, Typography } from '@material-ui/core';
import Loader from '../Loader/Loader';
import { connect } from 'react-redux';
import moment from 'moment';
import './ToolActivity.scss';
import { ExportCSV } from '../ExportCSV/ExportCSV';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        marginBottom:30
    },
    table: {
        width: '100%',
        border: '1px solid #D3D8E8',
        background:'#fff',
        boxShadow: '0 2px rgba(0,0,0,0.19)',
    },
    paginationBlock:{
        marginTop:10,
        marginBottom:10,

    }
}));

function ToolActivity({ match, dispatch, user, loading, role }) {
  const classes = useStyles();
  const toolName = match.params.toolname;
  const [totalActivity, setTotalActivity] = useState([]);
  const [activity, setActivity] = useState([]);
  const [activityDownload, setActivityDownload] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = useState(0);
  

  const toolMap = {
    cozone : "Salesforce CoZone",
    aura2lwc : "Aura2LWC",
    valuate : "Lightning Valuate",
    uitestbot : "Test Bot",
    dynamicui : 'Lightning Dynamic UI (Aura)',
    evaluator : "Lightning Evaluator"
  }

  function makeDate(createdDate) {
    let date = moment(createdDate);
    return date.format('Do MMM, YYYY');
  }

  function getToolActivityData() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user },
        body: JSON.stringify({
            "assetname" : toolMap[toolName]
        })
      };
      dispatch({ type: 'LOADING', response: true });
      fetch('/api/report/toolwisereport', requestOptions)
      .then(response => response.json())
      .then(data => {
          if (data && data.success) {
              if('data' in data){
                
                let download = [];
                data.data.forEach(element => {
                    download.push({
                        'Tracking Type':element.TrackingType,   
                        'User':element.User,
                        'Name':element.Name,
                        'Value':element.Value,
                        'Asset Name':element.AssetName,
                        'Created Date': element.CreatedDate
                    });
                });
                setTotal(data.data.length);                
                setTotalActivity(data.data);
                if(data.data && Math.ceil(data.data.length / rowsPerPage) > 1){
                    setTotalPages(Math.ceil(data.data.length/rowsPerPage));
                    console.log(Math.ceil(data.data.length / rowsPerPage));
                }else{
                    setTotalPages(1);
                }
                setActivity(data.data);
                console.log("totalPages"+totalPages);
                console.log("Total"+total)
              }
              dispatch({ type: 'LOADING', response: false });
          }
          dispatch({ type: 'LOADING', response: false });
        })
        .catch(err =>{
            console.log(err);
            dispatch({ type: 'LOADING', response: false });
        })
  };

  useEffect(() => {
    getToolActivityData();
  },[]);


  const handleChangePage = (event, newPage) => {
      if(newPage>0){
        setPage(newPage-1);
      }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <Container component="main" className="tools-activity">
        <Loader show={loading} />
        <div className={classes.paper}>
        {role=='admin'?
        <Link to="/admin/reports" className="back-button"><BackButton></BackButton><div>Back to Reports</div></Link>:
        <Link to="/user/reports" className="back-button"><BackButton></BackButton><div>Back to Reports</div></Link>
        }  
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Typography component="h6" variant="h6" style={{marginBottom:"20px"}}>
                    Tool Activity for {toolMap[toolName]}
                </Typography>
            </Grid>
            <Grid item xs={6} className="align-right">
            {(activityDownload && activityDownload.length>0) && <ExportCSV className="download" csvData={activityDownload} fileName={`Tool Activity for ${toolMap[toolName]}`} />}
            </Grid>
        </Grid>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell>Tracking Type</TableCell>
                            <TableCell align="left">User</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Value</TableCell>
                            <TableCell align="left">Asset Name</TableCell>
                            <TableCell align="left">Created Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        (activity.length>rowsPerPage?activity.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):activity).map((row) => (
                        <TableRow key={row.Id}>
                        <TableCell component="th" scope="row">
                            {row.TrackingType}
                        </TableCell>
                        <TableCell align="left">{row.User}</TableCell>
                        <TableCell align="left">{row.Name}</TableCell>
                        <TableCell align="left">{row.Value}</TableCell>
                        <TableCell align="left">{row.AssetName}</TableCell>
                        <TableCell align="left">{makeDate(new Date(row.CreatedDate))}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                
            </TableContainer>
            {
                totalPages>1 && (
                    <div className={classes.paginationBlock}>
                        <Pagination color="primary" count={totalPages} page={page+1} onChange={handleChangePage} />
                    </div>
                )
            }
        </div>
    </Container>
  );

                    }
function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.login.login,
        role: state.login.role
    }
}

export default connect(mapStateToProps)(ToolActivity);
