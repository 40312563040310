const initialState = {
  login:'',
  loginerror:'',
  role:''
}

const login = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGOUT_USER':
        return {
          ...state,
            login: action.response,
            role:'',
            loginerror:''
        };
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          login:action.response.token,
          role: action.response.role,
          email: action.response.email,
          loginerror: ''
        }; 
      case 'LOGIN_FAILURE':
        return {
          ...state,
          login:null,
          role:'',
          loginerror: action.response
        };
      case 'ADD_ROLE':
        return {
          ...state,
          role: action.response
        }
      default:
        return state;
    }
  }
  
  export default login;