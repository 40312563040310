import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import SignIn from './components/SignIn/SignIn';
import PurchaseLincense from './components/PurchaseLincense/PurchaseLincense';
import Container from '@material-ui/core/Container';
import ForgotPassword from './components/ForgotPass/ForgotPassword';
import ResetPassword from './components/ResetPass/ResetPassword';
import AppBar from './components/Appbar/Appbar';
import Dashboard from './components/Dashboard/Dashboard';
import ContactUs from './pages/ContactUs/ContactUs';
import SubmitComponent from './components/SubmitComponent/SubmitComponent';
import SubmitUseCase from './components/SubmitUseCase/SubmitUseCase';
import DetailsPage from './pages/DetailsPage/DetailsPage';
import Approval from './components/Approval/Approval';
import CozoneComps from './components/Cozonecomps/Cozonecomps';
import DataCharts from './components/DataCharts/DataCharts';
import ToolActivity from './components/ToolActivity/ToolActivity';
import {createMuiTheme} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core';
import TrainingContent from './components/TrainingContent/TrainingContent';
import TrainingCalendar from './components/TrainingCalendar/TrainingCalendar';
import RecentActivities from './components/RecentActivities/RecentActivities';
import SimpleRegister from './components/SimpleRegister/SimpleRegister';
import Feedback from './components/Feedback/Feedback';
import Users from './components/Users/Users';
import MyDownloads from './components/MyDownloads/MyDownloads';
import {
    userIsAuthenticatedRedir, userIsNotAuthenticatedRedir, userIsAdminRedir,
    userIsAuthenticated, userIsNotAuthenticated
} from './Auth';
import UserCharts from './components/DataCharts/UserCharts';
import Footer from './components/Footer/Footer';

const theme = createMuiTheme({props: {MuiButtonBase: {disableRipple: true,},},});

const Login = userIsNotAuthenticatedRedir(SignIn)


function App() {
    return (
        <Router>
            <div className="App">
                <div className="container">
                    <MuiThemeProvider theme={theme}>
                        <AppBar/>
                        <Container>
                            {/* Public Routes*/}
                            <Route exact path="/" component={Login}/>
	    		{/*  <Route path="/login" component={Login}/>
                            <Route path="/register" component={SimpleRegister}/>
                            <Route path="/contactus" component={ContactUs}/>
                            <Route path="/forgot" component={ForgotPassword}/>
                            <Route path="/reset" component={ResetPassword}/> */}
                            <Route path="/approval/user" component={Approval}/>
			     <Route path="/contactus" component={ContactUs}/>

                            {/* Private Routes*/}
                            <Route path="/dashboard" component={userIsAuthenticatedRedir(Dashboard)}/>
                            <Route path="/submitComponent" component={userIsAuthenticatedRedir(SubmitComponent)}/>
                            <Route path="/details" component={userIsAuthenticatedRedir(DetailsPage)}/>
                            <Route path="/trainingContent" component={userIsAuthenticatedRedir(TrainingContent)}/>
                            <Route path="/component/:id" component={userIsAuthenticatedRedir(CozoneComps)}/>
                            <Route path="/trainingCalendar" component={userIsAuthenticatedRedir(TrainingCalendar)}/>
                            <Route path="/recentactivities" component={userIsAuthenticatedRedir(RecentActivities)}/>
                            <Route path="/submitUseCase" component={userIsAuthenticatedRedir(SubmitUseCase)}/>
                            <Route path="/feedback" component={userIsAuthenticatedRedir(Feedback)}/>
                            <Route path="/users" component={userIsAuthenticatedRedir(userIsAdminRedir(Users))}/>
                            <Route path="/purchaselicense" component={userIsAuthenticatedRedir(PurchaseLincense)}/>
                            <Route path="/admin/reports" component={userIsAuthenticatedRedir(userIsAdminRedir(DataCharts))}/>
                            <Route path="/user/reports" component={userIsAuthenticatedRedir(UserCharts)}/>
                            <Route path="/activity/:toolname" component={userIsAuthenticatedRedir(ToolActivity)}/>
                            <Route path="/mydownloads" component={userIsAuthenticatedRedir(MyDownloads)}/>
                        </Container>
                    </MuiThemeProvider>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
