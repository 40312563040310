import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormik } from 'formik';
import './Approval.scss';
import UserCard from './UserCard';
import LightningLogo from '../../assets/images/Salesforce_Cozone.png';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="#">
          Salesforce CoZone
        </Link>{' '}
        {new Date().getFullYear() }
        {'.'}
      </Typography>
    );
  }
  

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      margin: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
})); 

function Approval(props) {
    const classes = useStyles();
    const history = useHistory();
    const [user, setUser] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [input, setInput] = React.useState(false);

    const hashId = new URLSearchParams(props.location.search).get('id'); 
    const handleCloseAndRedirectToLogin = () => {
        history.push("/");
    };

    function handleApproval(values) {
        console.log(values);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        };
       fetch('/api/user/validateapproval', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLoading(false);
            handleCloseAndRedirectToLogin();
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
      });
    }

    function getApprovalUser() {
        const values = {
            approvalHash: hashId
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        };
        fetch('/api/user/getapproval', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setUser(data);
            formik.initialValues.wbsCode = data.wbsCode;
            formik.setFieldValue('wbsCode', data.wbsCode);     
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
 
    React.useEffect(() => {
        getApprovalUser();
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.wbsCode) {
            errors.wbsCode = 'Required';
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
          wbsCode: ''
        },
        validate,
        onSubmit: values => {
            console.log(values);
            setLoading(true);
            let isWBSChanged = false;
            if(values.wbsCode !== user.wbsCode)
                isWBSChanged = true;
            let new_values = {
                    isWBSChanged: isWBSChanged, 
                    wbsCode: values.wbsCode,
                    userId: user.Id, 
                    email: user.email
                }
           handleApproval(new_values);
         }
    });

    return (
        <Container component="main" maxWidth="md" className="approval-page">
            <Loader show={loading} />
                <div className={classes.paper}>
                    <Grid xs={12} style={{display:"flex"}} justify="center">
                        <img src={LightningLogo} height="120"/>
                    </Grid>
                    <Grid xs={12}>
                        <Typography component="h6" variant="h6">
                            Approve Professional Details
                        </Typography>
                    </Grid>
                    {user.Id ? <UserCard user={user}/> : ''}
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} className="approval-form">
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    id="wbsCode"
                                    label="WBS Code"
                                    name="wbsCode"
                                    autoComplete="wbsCode"
                                    onChange={formik.handleChange}
                                    value={formik.values.wbsCode}    
                                    error={Boolean(formik.touched.wbsCode && formik.errors.wbsCode) }
                                    helperText={(formik.touched.wbsCode && formik.errors.wbsCode) && formik.errors.wbsCode}
                                    disabled = {input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" required size="small"/>}
                                label="I approve this WBS to purchase the Lightning Suite license for the project and this user."
                                onClick={()=> input ? setInput(false) : setInput(true)}
                            />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            Approve 
                        </Button> 
                    </form>
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
        </Container>
    )
}

export default Approval;