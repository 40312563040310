import React from 'react';
import Button from '@material-ui/core/Button';
import {useHistory, Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import LightningLogo from '../../assets/images/lightning_cozone.png';
import  './ForgotPassword.scss';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" to="/">
          Lightning CoZone
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: 'black'
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));



function ForgotPassword() {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const validate = values => {
      const errors = {};
      if(!values.email) {
          errors.email = 'Required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
      }
      return errors;
    }

    function handleForgotPassword(values){
      console.log(values);
      if(values){
        setLoading(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values)
        };
        fetch('/api/forgotpassword', requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            if (data.success) {
              setError('');
              setOpen(true);
            }else if(!data.success && data.message){
              setError(data.message);
            }
          })
          .catch(err => {
            setLoading(false);
            console.log(err);
            if(err.message){
              setError(err.message);
            }
            setError(err);
          })
      }
    }

    const handleCloseAndRedirectToLogin = () => {
      setOpen(false);
      history.push("/");
    };

    const formik = useFormik({
      initialValues: {
          email: '',
      },
      validate,
      onSubmit: values => {
        handleForgotPassword(values);
      },
    });
    return (
      <Container component="main" maxWidth="xs">
      <Loader show={loading} />
        <Dialog
          open={open}
          onClose={handleCloseAndRedirectToLogin}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">{"Forgot Password"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              An email has been sent to you with instruction to reset password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAndRedirectToLogin} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.paper}>
            <Grid container justify="center">
                <div className={classes.deloitteAvatar}>
                    <img src={LightningLogo} height="120"/>
                </div>
            </Grid>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Typography variant="body2" className="forgot-pass">
            <span className="text-small-2">Enter your registered email address to reset your password:</span>
          </Typography>
          <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={(formik.touched.email && formik.errors.email) && formik.errors.email}
              />
              {error && <Typography component="h3" variant="body1" style={{'textAlign':'center','margin':'10px 0'}}>
                        <span className="text-small-2" style={{color:'#da291c'}}>{error}</span>
              </Typography>}

              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Send Verification Link
              </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    )
}

export default ForgotPassword;