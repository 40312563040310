import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SideNav from '../SideNav/SideNav';
import jwtDecode from 'jwt-decode';
import { useHistory } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import logout from '../../actions/logout';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer'
  },
}));

function MenuAppBar({ dispatch, user, role }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sideNav, setSideNav] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const open = Boolean(anchorEl);
  let history = useHistory();
  

  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    sideNav ? setSideNav(false) : setSideNav(true);
  };
  React.useEffect(() => {
    if(user) {
      setUserDetails(jwtDecode(user));
    }
  },[user]);

  const handleChange = (event) => {
    //setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitComp = () =>{
    setAnchorEl(null);
    history.push('/submitComponent');
  }

  const handleAdminReports = () => {
    setAnchorEl(null);
    history.push('/admin/reports');
  }

  const handleUserReports = () => {
    setAnchorEl(null);
    history.push('/user/reports');
  }

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logout(history));
  }

  return (
    <div className={classes.root}>
    {user && userDetails ?  (<div>
    <SideNav open={sideNav} onToggle={toggleDrawer} username={userDetails} />
      <AppBar position="fixed" style={{backgroundColor: "#000"}}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} id="hamburger" color="inherit" aria-label="menu" onClick={toggleDrawer} >
            <MenuIcon />
          </IconButton>
            <Typography variant="h6" className={classes.title} onClick={() => history.push('/dashboard/')}>
              <span className="text-bold text-level-10" style={{'color': 'white'}}>Salesforce <span style={{'color': '#86BC25'}}>CoZone</span></span>
            </Typography>
              <IconButton aria-label="account of current user" aria-controls="menu-appbar" className="account-button" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <AccountCircle /> &nbsp;{userDetails.name}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSubmitComp}>
                  <span className="text-small-2">Submit Components</span>
                </MenuItem>
                {role && role ==='admin' && <MenuItem onClick={handleAdminReports}>
                  <span className="text-small-2">Admin Reports</span>
                </MenuItem>}
                {role && role ==='licensed' && <MenuItem onClick={handleUserReports}>
                  <span className="text-small-2">User Reports</span>
                </MenuItem>}
              {/*<MenuItem onClick={handleLogout}><span className="text-small-2">Logout</span></MenuItem>*/}
              </Menu>
        </Toolbar>
      </AppBar>
    </div>):(
      <AppBar position="fixed" style={{backgroundColor: "#000"}}>
      <Toolbar variant="dense">
          <Typography variant="h6" className={classes.title} onClick={() => history.push('/dashboard/')}>
            <span className="text-bold text-level-10" style={{'color': 'white'}}>Salesforce <span style={{'color': '#86BC25'}}>CoZone</span></span>
          </Typography>
      </Toolbar>
    </AppBar>
    )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
      user: state.login.login,
      role: state.login.role
  }
}

export default connect(mapStateToProps)(MenuAppBar);
