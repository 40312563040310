import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { Link } from "react-router-dom";
import './ContactUs.scss';
// profile images
import PradeepKamat from '../../assets/images/Pradeep_Kamat.jpg';
import SoumendraMohapatra from '../../assets/images/Soumendra__Mohapatra.jpg';
import KalyanaSundaram from '../../assets/images/Kalyan_Sundaram.jpg';
import RajanKapoor from '../../assets/images/Rajan_Kapoor.jpg';
import ManishMotwani from '../../assets/images/Manish.jpg';
import AruSankaranarayanan from '../../assets/images/Aru_Sankaranarayanan.jpg';
import AnkurRuparelia from '../../assets/images/Ankur_Ruparelia.jpg';
import RajeshKumar from '../../assets/images/RajeshKumar.jpg';
import ShishirChauhan from '../../assets/images/Shishir_Chauhan.jpg';
import RahulBussa from '../../assets/images/Rahul_Bussa.jpg';
import VidyaDas from '../../assets/images/Vidya_Das.jpg';
import SatyaKona from '../../assets/images/Satya_Kona.jpg';
import RahulYadav from '../../assets/images/Rahul_Yadav.jpg';
import AnimeshPandey from '../../assets/images/Animesh_Pandey.jpg';
import NikhilNaik from '../../assets/images/NikhilNaik.jpg';
import GauriParab from '../../assets/images/GauriParab.jpg';
import SandipJapannavar from '../../assets/images/Sandip_Japannavar.jpg';
import MuraliRaghvan from '../../assets/images/Murali_Raghvan.jpg';
import SrivatsanKoviloor from '../../assets/images/Srivatsan_Koviloor.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  dashboardTitle: {
    textAlign: 'left',
    margin: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  flexContainer: {
    display: 'block',
    padding: 0,
  },
  flexListItem: {
    display: 'inline-flex',
    width: '33%',
  },
  listItemText: {
    marginLeft: theme.spacing(2),
    color: '#86BC25',
    fontWeight: 'bold',
  },
  contactUsSubTitle: {
    color: '#000000',
    fontWeight: 'bold',
    margin: "20px"
  },
  contactUsBoxTitle: {
    color: '#ffffff',
    textTransform: 'uppercase',
    padding: theme.spacing(2),
    backgroundColor: '#000000',
    marginBottom: "15px"
  },
  boxContainer: {
    marginLeft: theme.spacing(2),
    padding: 0
  },
  contactUsGrid: {
    marginBottom: theme.spacing(6)
  },
  contactUsTitle: {
    color: "#000000",
    margin: "20px"
  },
  summary: {
    width: '100%',
    margin: theme.spacing(2, 2, 0, 2),
    border: '1px solid #D3D8E8',
    borderRadius: '6px',
    background:'#fff',
    boxShadow: '0 2px rgba(0,0,0,0.19)',
  }
}));

export default function ContactUs() {
  const classes = useStyles();

  return (
    <Container component="main">
      <div className={classes.paper}>
        <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
        <Typography component="h1" variant="h5" className={classes.contactUsTitle}>
          Contact Us
        </Typography>
        <div className={classes.contactUsGrid}>
          <Typography component="h6" className={classes.contactUsSubTitle}>
            Here are key contact details:
          </Typography>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>US</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Pradeep Kamat" src={PradeepKamat} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Pradeep Kamat"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Pradeep Kamat" src={SoumendraMohapatra} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Soumendra Mohapatra" className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>USI</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Kalyana Sundaram" src={KalyanaSundaram} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Kalyana Sundaram"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Rajan Kapoor" src={RajanKapoor} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Rajan Kapoor" className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Manish Motwani" src={ManishMotwani} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Manish Motwani"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Aru Sankaranarayanan" src={AruSankaranarayanan} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Aru Sankaranarayanan" className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Ankur Ruparelia" src={AnkurRuparelia} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Ankur Ruparelia"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Rajesh Kumar" src={RajeshKumar} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Rajesh Kumar" className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
        </div>

        <div container className={classes.contactUsGrid}>
          <Typography component="h6" className={classes.contactUsSubTitle}>
            Lightning Threads &amp; Contacts:
          </Typography>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>Salesforce CoZone</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Rahul Bussa" src={RahulBussa} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Rahul Bussa" className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>Lightning Auragen</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Vidya Das" src={VidyaDas} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Vidya Das"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Satya Kona" src={SatyaKona} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Satya Kona" className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>Lightning Transformation App</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Rahul Yadav" src={RahulYadav} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Rahul Yadav"  className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>Lightning Training</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Animesh Pandey" src={AnimeshPandey} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Animesh Pandey"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Nikhil Naik" src={NikhilNaik} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Nikhil Naik" className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Gauri Parab" src={GauriParab} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Gauri Parab" className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
          <div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>Lightning Reusable Components</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Sandip Japannavar" src={SandipJapannavar} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Sandip Japannavar"  className={classes.listItemText} />
              </ListItem>
            </List>
          </div>
          {/*<div className={classes.summary} bgcolor="background.paper">
            <div className="report-summary-title sub-heading">
              <span>Lightning Reusable Components</span>
            </div>
            <List className={classes.flexContainer}>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Murali Raghvan" src={MuraliRaghvan} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Murali Raghvan"  className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.flexListItem}>
                <ListItemAvatar>
                  <Avatar alt="Srivatsan Koviloor" src={SrivatsanKoviloor} className={classes.large} />
                </ListItemAvatar>
                <ListItemText primary="Srivatsan Koviloor" className={classes.listItemText} />
              </ListItem>
            </List>
          </div>*/}
        </div>
      </div>
    </Container>
  );
}