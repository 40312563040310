import React from 'react';
import {useHistory, Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import { useFormik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 2, 2, 0),
    width: '20%'
  },
  formControl: {
    width: '100%'
  }
}));


export default function SubmitUseCase() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);
  const [useCaseInfo, setUseCaseInfo] = React.useState(null);
  const months = [
    { value: 'January', text: 'January' },
    { value: 'February', text: 'February' },
    { value: 'March', text: 'March' },
    { value: 'April', text: 'April' },
    { value: 'May', text: 'May' },
    { value: 'June', text: 'June' },
    { value: 'July', text: 'July' },
    { value: 'August', text: 'August' },
    { value: 'September', text: 'September' },
    { value: 'October', text: 'October' },
    { value: 'November', text: 'November' },
    { value: 'December', text: 'December' }
  ];
  let dialogActions;

  const handleCancel = () => {
    setOpen(false);
  };

  function getUseCaseData(event) {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    fetch(`/api/sf/usecasemonth?month=${event.target.value}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setUseCaseInfo(data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  function handleSubmitUseCaseComponent(values) {
    console.log(values);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    };
    fetch('/api/sf/usecasesave', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLoading(false);
        setFormSubmitted(true);
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  function handleCloseAndSubmit(formValue) {
    if (!formSubmitted) {
      handleSubmitUseCaseComponent(formValues);
    } else {
      setOpen(false);
      setUseCaseInfo(null);
      setFormSubmitted(false);
      formik.handleReset();
    }
  }

  const validate = values => {
    const errors = {};
    if (!values.month) {
      errors.month = 'Required';
    }
    if (!values.team) {
      errors.team = 'Required';
    }
    if (!values.link) {
      errors.link = 'Required';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      month: '',
      team: '',
      link: ''
    },
    validate,
    onSubmit: values => {
      setOpen(true);
      setFormValues(values);
    },
  });

  if (!formSubmitted) {
    dialogActions = <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleCloseAndSubmit(formValues) } color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>;
  } else {
     dialogActions = <DialogActions>
          <Button onClick={() => handleCloseAndSubmit(formValues) } color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>;
  }

  return (
    <Container component="main" maxWidth="md">
      <Loader show={loading} />
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { formSubmitted ? 'Thanks for submitting. We will get back to you shortly' : 'Are you sure you want to submit? Once submitted you cannot edit or resubmit.' }
          </DialogContentText>
        </DialogContent>
        {dialogActions}
      </Dialog>
      <div className={classes.paper}>
        <Link to="/dashboard" className="back-button"><BackButton></BackButton><div>Back to Dashboard</div></Link>
        <Typography component="h1" variant="h5">
          Submit Case Study Of Month
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl} error={Boolean(formik.touched.month && formik.errors.month) }>
                <InputLabel id="month">Month</InputLabel>
                <Select
                  labelId="month"
                  id="month"
                  value={formik.values.month}
                  onChange={(e) => {
                      formik.handleChange(e);
                      getUseCaseData(e);
                    }
                  }
                  onBlur={formik.handleBlur}
                  label="Month"
                  name="month"
                  >
                  {
                    months.map((month) => {
                      return <MenuItem value={month.value}>{month.text}</MenuItem>
                    })
                  }
                </Select>
                <FormHelperText>{(formik.touched.month && formik.errors.month) && formik.errors.month}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl} error={Boolean(formik.touched.team && formik.errors.team) }>
                <InputLabel id="team">Team</InputLabel>
                <Select
                  labelId="team"
                  id="team"
                  value={formik.values.team}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Team"
                  name="team"
                  >
                  {
                    (useCaseInfo || []).map((data) => {
                      return <MenuItem value={data.Id}>{data.team_name__c}</MenuItem>
                    })
                  }
                </Select>
                <FormHelperText>{(formik.touched.team && formik.errors.team) && formik.errors.team}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="link"
                label="Unassigned Package URL"
                name="link"
                autoComplete="projectName"
                onChange={formik.handleChange}
                value={formik.values.link}
                error={Boolean(formik.touched.link && formik.errors.link) }
                helperText={(formik.touched.link && formik.errors.link) && formik.errors.link}
                />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
}