import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function UserCard({ user }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
console.log(user);
  return (
    <Grid container>
      <Grid xs={3}>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {user.firstName + ' ' + user.lastName}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {user.email}
            </Typography>
            <Typography variant="body2" component="p">
              {user.project}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={9}>
        <Card className={classes.root} elevation={0}>
          <Grid style={{display:"flex"}} justify="space-around" spacing={3}>
            <div>
            <Typography variant="caption" component="p" gutterBottom>
                Partner: <strong>{user.partnerName}</strong>
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
                USI GDM: <strong>{user.usiGDMName}</strong>
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
                Email: <strong>{user.usiGDMEmail}</strong>
            </Typography>
            </div>
            <div>
            <Typography variant="caption" component="p" gutterBottom>
                PPMD: <strong>{user.ppmd}</strong>
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
                US GDM:  <strong>{user.usGDMName}</strong>
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
                Email: <strong>{user.usGDMEmail}</strong>
            </Typography>
            </div>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
