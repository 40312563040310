import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jsforce from 'jsforce';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './SfFileupload.scss';

function SfFileupload({ login, loading, dispatch, onfileupload}) {

    const [files, setFiles] = useState(null);
    const [uploadFiles, setUploadFiles] = useState(null);

    function fileChangedHandler(event){
        const file = event.target.files[0];
        setFiles(file);
    }

    function uploadHandler(){
        console.log(files);
        if(files){
            var fileName = getFileNameWithExtension( files );
            console.log(fileName);
            getBase64(files).then((constructedFile) => {
                    saveFile( fileName, constructedFile.base64StringFile, '' );
            })
        }
    }

    function getFileNameWithExtension( file ) {

        var extIndex = file.name.lastIndexOf( '.' );
        var extension = file.name.substring( extIndex );
        var fileName = file.name.substring( 0, extIndex );
            fileName = fileName.replace( /\./g, '_' );
            fileName += extension;
        return fileName;
    }


    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onerror = () => {
            reader.abort();
            reject(console.error('Problem uploading File'));
          }
          reader.onloadend = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
              encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve({
              uri: reader.result,
              base64StringFile: encoded,
              fileName: getFileNameWithExtension(file),
              fileType: file.type
            });
          };
        });
      }

    function saveFile(fileName, base64, recordId){
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        dispatch({ type: 'LOADING', response: true });
        fetch('/api/sf/onetimesftoken', requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data && 'token' in data) {
                const conn =  new jsforce.Connection({instanceUrl:data.link, accessToken: data.token });
                console.log(conn);
                var path = '/services/data/v42.0';
                conn.requestPost( path + '/composite/', {
                    'allOrNone' : true,
                    'compositeRequest' : [
                        {
                            'method' : 'POST',
                            'url' : path + '/sobjects/ContentVersion/',
                            'referenceId' : 'newFile',
                            'body' : {
                                'Title' : fileName,
                                'PathOnClient' : fileName,
                                'VersionData' : base64,
                                // 'FirstPublishLocationId' : recordId
                            }
                        }
                    ]
                })
                .then( validateCompositeResponse )
                .then( function( response ) {
                    dispatch({ type: 'LOADING', response: false });
                    // get new file id
                    var contentVersionId = null;
                    for ( var i = 0; i < response.compositeResponse.length; i++ ) {
                        if ( response.compositeResponse[i].referenceId === 'newFile' ) {
                            contentVersionId = response.compositeResponse[i].body.id;
                            break;
                        }
                    }
                    console.log("Created content version id");
                    console.log(contentVersionId);
                    if(contentVersionId){
                        setUploadFiles({fileName:fileName,fileId:contentVersionId});
                        onfileupload({fileName:fileName,fileId:contentVersionId, orgLink:data.link });
                    }else{
                        onfileupload(null);
                    }

                    // ... do something with the new file id
    
                }).catch( function( err ) {
                    dispatch({ type: 'LOADING', response: false });
                    console.error( err );
                    // ... show error to user
    
                }).then( function() {
                    dispatch({ type: 'LOADING', response: false });
                    console.log("File uploaded successfully");

                })

            }
        })
        .catch(err => {
            dispatch({ type: 'LOADING', response: false });
            console.log(err)
        });
    }

    function validateCompositeResponse( response ) {
        return new Promise( function( resolve, reject ) {
            console.log('validating composite response');
            for ( var i = 0; i < response.compositeResponse.length; i++ ) {
                var body = response.compositeResponse[i].body[0];
                // ignore the 'processing halted' messages as that just indicates
                // that sub-request wasn't the one that blew up, keep looking
                if ( body && body.errorCode && body.errorCode != 'PROCESSING_HALTED' ) {
                    reject( body.message );
                }
            }        
            resolve( response );          
        });
    }

    return (
        <div>
            <Grid container>      
                <Grid item xs={12} sm={6}>
                <input type="file" onChange={fileChangedHandler} />
                </Grid>
                <Grid item xs={12} sm={6} className="align-center">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={uploadHandler}
                >
                    Upload
                </Button>
                </Grid>
            </Grid>
            <Grid container>   
                <Grid item xs={12} className="align-center">
                    {
                        uploadFiles && (
                            <span>{uploadFiles.fileName}</span>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    );
}
SfFileupload.propTypes = {
    login: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
  }
  
  function mapStateToProps(state) { 
    return {
        loading: state.loading,
        login: state.login.login
    }
  }
  
export default connect(mapStateToProps)(SfFileupload);
