import React, { Component } from 'react';
import moment from 'moment';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import 'react-big-calendar/lib/sass/styles.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import './style.scss';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { Link } from "react-router-dom";

export default class TrainingCalendar extends Component {
    momentDate = new Date();
    constructor(props) {
        super(props);
        this.state  = {
            locations: ['Hyderabad', 'Bengaluru', 'Mumbai'],
            trainingDetails: [],
            filteredTrainings: [],
            showModal: false,
            selectedTraining: {
                title: '',
                location: '',
                data: [],
            },
        };
    }

    componentDidMount() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify()
        };
        fetch('/api/sf/trainingcontent', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({trainingDetails: data });
                this.handleLocationChange(this.state.locations[0]);
            })
            .catch(err => {
            console.log(err);
            });
            this.momentDate = moment().toDate();
    }

    handleLocationChange = (value) =>{
        const trainingDetails = [...this.state.trainingDetails['data']];
        const locTrainingDetails = trainingDetails.filter(item => item['Location__c'] === value);
        const filteredTrainingDetails = [];
        locTrainingDetails.forEach((event, index) => {
            let start = moment(event.Start_Date__c || new Date()).toDate();
            let end = moment(event.End_Date__c || new Date()).toDate();
            filteredTrainingDetails.push({ id: event.Id, title: event.Title__c, start: start, end: end, color: '#FFFB00' })
        });
        this.setState({ filteredTrainings: filteredTrainingDetails});
    } 

    onSelectEvent = (event) => {
        const selectedTraining = [...this.state.trainingDetails['data']].filter(item => item['Id'] === event.id);
        if(selectedTraining.length > 0) {
            this.setState({showModal: true});
            console.log(selectedTraining[0])
            const training = {
                title: selectedTraining[0]['Title__c'],
                location: selectedTraining[0]['Location__c'],
                data: selectedTraining[0]['cozone_sessions__r']['records'],
            };
            this.setState({selectedTraining: training});
        }
    }

    render() {
        const localizer = momentLocalizer(moment);
        return (
        <Container className="training-calendar-content">
            <div className="calendar-wrapper">
            <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
                <Modal className="project-modal" show={this.state.showModal} centered={true}  size="lg" animation={true}>
                    <Modal.Header className="font-weight-bold text-level-7">{this.state.selectedTraining['title']}</Modal.Header>
                        <Modal.Body>
                            <div className="text-level-8 mb-3">Location : {this.state.selectedTraining['location']}</div>
                            <div>
                                <table border="1" cellPadding="10" width="100%">
                                    <thead>
                                        <tr className="text-level-8">
                                            <td>Day</td>
                                            <td>Topics</td>
                                            <td>Trainer</td>
                                            <td>Training Room</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.selectedTraining['data'].map((item, index) => 
                                            <tr key={index} className="text-level-8">
                                                <td>{item['Day__c']}</td>
                                                <td>{item['Topic__c']}</td>
                                                <td>{item['Instructor__c']}</td>
                                                <td>{item['Meeting_Room__c']}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({showModal: false})}>Close</Button>
                        </Modal.Footer>
                </Modal>
                    <Row>
                        <Col lg={12}>
                            <Form>
                                <Form.Group>
                                    <Form.Label className="text-bold text-level-12">Location</Form.Label>
                                    <Form.Control as="select"
                                        className="text-small-2 location-select"
                                        value={this.state.location}
                                        onChange={(event) => {this.handleLocationChange(event.target.value)}}>
                                        {this.state.locations.map((location, index) => <option key={index} value={location}>{location}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="training-calendar text-small-2 mt-3" xs={12}>
                            {this.state.trainingDetails['hasError'] === undefined &&
                                (
                                    <Row>
                                        <Col>
                                            <div>Training Details are getting loaded ...</div>
                                        </Col>
                                    </Row>
                                )}
                            {this.state.trainingDetails['hasError'] !== undefined && this.state.filteredTrainings.length === 0 &&
                                (
                                    <Row>
                                        <Col>
                                            <div>No Training details to show for this location.</div>
                                        </Col>
                                    </Row>
                                )}
                            {this.state.filteredTrainings.length > 0 &&
                                (<Calendar
                                    localizer={localizer}
                                    events={this.state.filteredTrainings}
                                    startAccessor="start"
                                    endAccessor="end"
                                    onSelectEvent={event => this.onSelectEvent(event)}
                                    defaultDate={this.momentDate}
                                />)}
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}