import React, { Component } from 'react';
import  './Footer.scss';


export default class Footer extends Component {
  render() {
    return (
      <div className="footerContainer">
      <footer className="footer z-100">
          <div>
              <a
                  className="text-blue"
                  href="https://cookienotice.deloitte.com"
                  target="_blank"
                  rel="noreferrer"
              >
                  Cookies
              </a>
              {/* <a className="text-blue-ribbon ml-4 font-semibold cursor-pointer optanon-toggle-display">
                  Cookie Settings
              </a> */}
          </div>
      </footer>
  </div>
    );
}
}


