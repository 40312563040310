import React from 'react';
import {useHistory, Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import { useFormik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import './SubmitComponent.scss';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 2, 2, 0),
  },
  formControl: {
    width: '100%', 
  }
}));


export default function SubmitComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCloseAndResetData = () => {
    handleClose();
    formik.handleReset();
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  function handleReusableComponent(values) {
    console.log(values);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    };
    fetch('/api/sf/submitcomponent', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLoading(false);
        formik.handleReset();
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  const validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Required';
    }
    if (!values.curStateName) {
      errors.curStateName = 'Required';
    }
    if (!values.type) {
      errors.type = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.project) {
      errors.project = 'Required';
    }
    if (!values.link) {
      errors.link = 'Required';
    }
    if (!values.curStateDesc) {
      errors.curStateDesc = 'Required';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      curStateName: '',
      type: '',
      email: '',
      project: '',
      link: '',
      curStateDesc: '',
      duedate: new Date().toISOString()
    },
    validate,
    onSubmit: values => {
      setLoading(true);
      handleReusableComponent(values);
    },
  });

  return (
    <Container component="main" maxWidth="md">
      <Loader show={loading} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Successfully Created"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          The reusable component has been created
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAndResetData} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.paper}>
        <Link to="/dashboard" className="back-button"><BackButton></BackButton><div>Back to Dashboard</div></Link>
        <Container  maxWidth="xs">
        <Typography component="h1" variant="h5">
          Submit Reusable Component
        </Typography> 
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="username"
                name="username"
                variant="outlined"
                fullWidth
                id="username"
                label="Your Name"
                autoFocus
                onChange={formik.handleChange}                
                value={formik.values.username}
                error={Boolean(formik.touched.username && formik.errors.username) }
                helperText={(formik.touched.username && formik.errors.username) && formik.errors.username}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="curStateName"
                label="Component Name"
                name="curStateName"
                autoComplete="curStateName"
                onChange={formik.handleChange}                
                value={formik.values.curStateName}
                error={Boolean(formik.touched.curStateName && formik.errors.curStateName) }
                helperText={(formik.touched.curStateName && formik.errors.curStateName) && formik.errors.curStateName}
                />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="label-type">Type</InputLabel>
                <Select
                  labelId="label-type"
                  id="type"
                  label="Type"
                  name="type"
                  autoComplete="type"
                  onChange={formik.handleChange}                
                  value={formik.values.type}
                  error={Boolean(formik.touched.type && formik.errors.type)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Aura'}>Aura</MenuItem>
                  <MenuItem value={'LWC'}>LWC</MenuItem>
                </Select>
              </FormControl>
              {formik.errors.type &&
                formik.touched.type &&
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                  {formik.errors.type}
                </p>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={formik.handleChange}                
                value={formik.values.email}
                error={Boolean(formik.touched.email && formik.errors.email) }
                helperText={(formik.touched.email && formik.errors.email) && formik.errors.email}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="project"
                label="Project"
                id="project"
                autoComplete="project"
                onChange={formik.handleChange}                
                value={formik.values.project}
                error={Boolean(formik.touched.project && formik.errors.project) }
                helperText={(formik.touched.project && formik.errors.project) && formik.errors.project}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="link"
                label="Unassigned Package URL"
                name="link"
                autoComplete="projectName"
                onChange={formik.handleChange}                
                value={formik.values.link}
                error={Boolean(formik.touched.link && formik.errors.link) }
                helperText={(formik.touched.link && formik.errors.link) && formik.errors.link}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="curStateDesc"
                label="Use Case"
                name="curStateDesc"
                autoComplete="curStateDesc"
                onChange={formik.handleChange}                
                value={formik.values.curStateDesc}
                error={Boolean(formik.touched.curStateDesc && formik.errors.curStateDesc) }
                helperText={(formik.touched.curStateDesc && formik.errors.curStateDesc) && formik.errors.curStateDesc}
                />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            >
            Submit
          </Button>
        </form>
        </Container>
        </div>
    </Container>
  );
}