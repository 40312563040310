import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader/Loader";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

// Styles
import "./DataCharts.scss";
import 'react-dates/lib/css/_datepicker.css';

import lightningcozone from "../../assets/images/Salesforce_Cozone.png";
import Aura2lwc from "../../assets/images/AURA2LWC.png";
import dynamicInt from "../../assets/images/LDinterface.png";
import evaluator from "../../assets/images/LIGHTNINGEVALUATOR.png";
import valuate from "../../assets/images/LightningValuate.png";
import uibot from "../../assets/images/UI_Test_Bot.png";

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ExportCSV } from "../ExportCSV/ExportCSV";
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import CozoneDownloadAnalytics from "../CozoneDownloadAnalytics/CozoneDownloadAnalytics";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  cardroot: {
    display: "flex",
    minHeight: 120,
    justifyContent: "space-between",
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 12,
  },
  content: {
    flex: "1 0 auto",
    alignItems: "center",
  },
  cover: {
    width: 100,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 120,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  select: {
    background: "#fff",
    border: '1px solid black'
  },
  table: {
    width: '100%',
    background:'#fff',
  },
}));

const DataCharts = ({ loading, dispatch, login }) => {
  const [userData, setUserData] = useState([]);
  const [toolData, setToolData] = useState();
  const [toolDownlod, setToolDownload] = useState([]);
  const [reportTypeData, setReportTypeData] = useState();
  const [toolActualData, setToolActualData] = useState([]);
  const [isToolChildActive, setIsToolChildActive] = useState(null);
  const [userStartDate, setUserStartDate] = useState(moment().day(-30));
  const [userEndDate, setUserEndDate] = useState(moment());
  const [toolStartDate, setToolStartDate] = useState(moment().day(-30));
  const [toolEndDate, setToolEndDate] = useState(moment());
  const [projectStartDate, setProjectStartDate] = useState((moment().day(-30)));
  const [projectEndDate, setProjectEndDate] = useState(moment());
  const [toolsProjectStartDate, setToolsProjectStartDate] = useState(moment().day(-30));
  const [toolsProjectEndtDate, setToolsProjectEndDate] = useState(moment());
  const [revenueStartDate, setRevenueStartDate] = useState(moment().day(-90));
  const [revenueEndDate, setRevenueEndDate] = useState(moment());
  const [userFocusedInput, setUserFocusedInput] = useState(null);
  const [projectFocusedInput, setProjectFocusedInput] = useState(null);
  const [toolsProjectFocusedInput, setToolsProjectFocusedInput] = useState(null);
  const [toolFocusedInput, setToolFocusedInput] = useState(null);
  const [revenueFocusedInput, setRevenueFocusedInput] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElTool, setAnchorElTool] = React.useState(null);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const usermenuopen = Boolean(anchorElUser);
  const toolmenuopen = Boolean(anchorElTool);
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [projectAnalysis, setProjectAnalysis] = useState();
  const [totalProjAnalysisCount, setTotalProjAnalysisCount] = useState(0);
  const [licensedUserData, setLicensedUserData] = useState([]);

  function addEmptyRows(data) {
      let rows = [];
      if (data.length < 6) {
        for(let i=0; i < (6 - data.length); i++)
          rows.push(
          <TableRow className="empty-rows">
            <TableCell align="center" component="th" scope="row">
                {' '}
            </TableCell>
            <TableCell component="th" scope="row">
                {' '}
            </TableCell>
            <TableCell align="left">{' '}</TableCell>
          </TableRow>
          );
      }
      return rows;
  }

  function getLicensedUsersData(start, end) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": login,
      },
      body: JSON.stringify({
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      })
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/report/revenuebasedreport", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "LOADING", response: false });
        if(data.success){
          if('data' in data){
            setLicensedUserData(data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADING", response: false });
      });
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
          <div className="custom-tooltip">
            { payload && <p className="label">{`${label} : ${payload[0].value}`}</p> }
            <p className="intro">{getLabels(label)}</p>
          </div>
      );
    }
    return null;
  }

  function getLabels(label){
    if(label === 'TestBot'){
        return 'No of Test cases executed';
    } else if (label === 'Valuate') {
      return 'Valuate Component report'
    } else if (label === 'Evaluator') {
      return 'No of Org Evaluation reports';
    } else if(label === 'Aura2LWC') {
      return 'No of Components converted from Aura to LWC';
    } else if(label === 'DynamicUI'){
      return 'No of Dynamic components generated';
    } else if(label === 'CoZone'){ //Changed to Salesforce CoZone - AK
      return 'No of reusable components downloaded';
    }
  }

  function getReportData(assetname, start, end) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": login,
      },
      body: JSON.stringify({
        startDate: start.toISOString(),
        endDate: end.toISOString(),
        assetname: assetname
      }),
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/report/projecttoolanalysis", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "LOADING", response: false });
        if(data.success){
          if('data' in data){
            setReportTypeData(data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADING", response: false });
      });
  }

  function getProjects() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "token": login,
      },
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/report/projects", requestOptions)
      .then(res => res.json())
      .then(data =>{
        dispatch({ type: "LOADING", response: false });
        setProjectList(data['projects']);
        getReportsFromProject(data['projects'][0], toolsProjectStartDate, toolsProjectEndtDate);
      })
  }

  function getReportsFromProject(project, start, end) {
    setSelectedProject(project);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": login,
      },
      body: JSON.stringify({
        project: project,
        startDate: start.toISOString(),
        endDate: end.toISOString()
      }),
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/report/projecttoolanalysis", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "LOADING", response: false });
        if(data.success){
          const reports = data.data;
          let totalCount = 0;
          const toolsMap = {
            'Test Bot':'TestBot',
            'Lightning Valuate':'Valuate',
            'Lightning Evaluator':'Evaluator',
            'Aura2LWC':'Aura2LWC',
            'Lightning Dynamic UI (Aura)':'DynamicUI',
            'Salesforce CoZone':'CoZone' 
          }
          reports.report.forEach(item =>{
            totalCount = totalCount + Number(item['count']);
            item['AssetName'] = toolsMap[item['AssetName']];
          })
          setProjectAnalysis(reports);
          setTotalProjAnalysisCount(totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADING", response: false });
      });
  }

  function getUserData(start, end) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": login,
      },
      body: JSON.stringify({
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      }),
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/admin/report/userdetails", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "LOADING", response: false });
        if(data.success){
          if('data' in data){
            setUserData(data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADING", response: false });
      });
  }

  function getToolData(start, end) {
    const values = {
      startDate: start.toISOString(),
      endDate: end.toISOString(),
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","token": login },
      body: JSON.stringify(values),
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/admin/report/toolanalysis", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "LOADING", response: false });
        if(data.success){
          if('data' in data){
            const toolsMap = {
              'Test Bot':'TestBot',
              'Lightning Valuate':'Valuate',
              'Lightning Evaluator':'Evaluator',
              'Aura2LWC':'Aura2LWC',
              'Lightning Dynamic UI (Aura)':'DynamicUI',
              'Salesforce CoZone':'CoZone' //Changed to Salesforce CoZone - AK
            }
            const result = data.data.report;
            for(let i=0;i<result.length;i++){
              let toolName = result[i].AssetName;
              if(toolName && toolName in toolsMap){
                result[i].AssetName = toolsMap[toolName];
              }
            }
            setToolData(result);
            setToolActualData(result);
            setToolDownload(data.data.details);
          }
        }

      })
      .catch((err) => {
        dispatch({ type: "LOADING", response: false });
        console.log(err);
      });
  }

  function refreshUserData(startDate, endDate) {
    if(startDate && endDate){
      getUserData(startDate, endDate);
    }
  }

  function refreshToolData(startDate, endDate) {
    if(startDate && endDate){
      getToolData(startDate, endDate);
    }
  }

  function refreshReportData(assetname, startDate, endDate) {
    if(startDate && endDate){
      getReportData(assetname, startDate, endDate);
    }
  }

  function refreshReportsFromProjectData(selectedProject, startDate, endDate) {
    if(startDate && endDate){
      getReportsFromProject(selectedProject, startDate, endDate);
    }
  }

  function refreshLicensedUserData(startDate, endDate) {
    if(startDate && endDate){
      getLicensedUsersData(startDate, endDate);
    }
  }


  const onReportTypeSelect = (event) => {
    getReportData(event.target.value, projectStartDate, projectEndDate);
  };


  const handleToolClick = (data, index) => {
    if(('count' in data) && data.count >0 && ('children' in data)){
      setIsToolChildActive(data.AssetName);
      setToolData(data.children)
    }
  }

  const handleToolBack = () => {
    setIsToolChildActive(null);
    setToolData(toolActualData);
  }

  const classes = useStyles();

  useEffect(() => {
    dispatch({ type: "LOADING", response: true });
    getReportData('Test Bot', projectStartDate, projectEndDate);
    getUserData(userStartDate, userEndDate);
    getToolData(toolStartDate, toolEndDate);
    getProjects();
    getLicensedUsersData(revenueStartDate, revenueEndDate);
  },[initialLoad]);

  return (
    <Container omponent="main" className="reports">
      <Loader show={loading} />
      <div className={classes.paper}>
      <Link to="/dashboard" className="back-button"><BackButton></BackButton><div>Back to Dashboard</div></Link>
        <Grid container spacing={3}>
          <Grid item xs={12} className="page-header">
            <Typography component="h1" variant="h5" style={{marginBottom:"20px"}}>
              User and Asset Reports
            </Typography>
          </Grid>
        </Grid>

        {reportTypeData && <Grid container spacing={2} className="report-select reports-header first">
          <Grid item xs={8}>
            <h6>Asset Usage Report</h6>
          </Grid>
          <Grid item xs={4} className="selector">
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Select Report Type -
              </InputLabel>
              <Select value={reportTypeData.report[0].AssetName} onChange={onReportTypeSelect} disableEmpty={false} className={classes.select}  inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value="" disabled>Select Report Type</MenuItem>
                <MenuItem value={"Salesforce CoZone"}>Projects using Salesforce CoZone</MenuItem> 
                <MenuItem value={"Aura2LWC"}>Projects using Aura2LWC</MenuItem>
                <MenuItem value={"Lightning Valuate"}>Projects using Lightning Valuate</MenuItem>
                <MenuItem value={"Test Bot"}>Projects using Test Bot</MenuItem>
                <MenuItem value={"Lightning Dynamic UI (Aura)"}>Projects using Lightning Dynamic UI (Aura)</MenuItem>
                <MenuItem value={"Lightning Evaluator"}>Projects using Lightning Evaluator</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>}

        {reportTypeData && <Grid container spacing={2} className="report-select" >
          <Grid item xs={5} className="report-table">
            <TableContainer>
                  <Table className={classes.table} aria-label="simple table" stickyHeader>
                      <TableHead className="table-header">
                          <TableRow>
                              <TableCell align="center">#</TableCell>
                              <TableCell align="left">Project Name</TableCell>
                              <TableCell align="left">Usage</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      {reportTypeData.report[0].children.map((row, index) => {
                        if (index < 6) {
                          return (<TableRow key={row.Id}>
                            <TableCell align="center" component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.ProjectName}
                            </TableCell>
                            <TableCell align="left">{row.count}</TableCell>
                          </TableRow>);
                        }
                      })}
                      {addEmptyRows(reportTypeData.report[0].children)}
                      </TableBody>
                  </Table>
              </TableContainer>
          </Grid>
          <Grid item xs={7} className="report-charts" style={{height:"auto"}}>
            <div className="chartcard">
              <div className="header header-1">
                  <Grid item xs={4} className="header-title">
                    Top 5 Projects
                  </Grid>
                  <Grid item xs={8} className="header-cta">
                  <DateRangePicker
                        startDateId="projectStartDateId"
                        endDateId="projectEndDateId"
                        startDate={projectStartDate}
                        endDate={projectEndDate}
                        customArrowIcon={<SyncAltIcon/>}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }) => {setProjectStartDate(startDate);setProjectEndDate(endDate)}} // PropTypes.func.isRequired,
                        focusedInput={projectFocusedInput}
                        onFocusChange={focusedInput => setProjectFocusedInput(focusedInput)}
                        onClose={() => refreshReportData(reportTypeData.report[0].AssetName, projectStartDate, projectEndDate)}
                      />
                  <div className="report-info header-card-menu">
                    <ExportCSV csvData={reportTypeData.details} fileName={"Projects using " + reportTypeData.report[0].AssetName} noText={true} />
                  </div>
                  </Grid>
              </div>
              <div className="content">
                <ResponsiveContainer>
                  <BarChart data={reportTypeData.report[0].children.slice(0,5)} margin={{ top: 15, right: 10, left: 10, bottom: 15 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="ProjectName" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<p>Project Tool Usage</p>} />
                    <Bar dataKey="count" fill="#86BC25" label={{ fontSize: 10 }} barSize={24} minPointSize={3} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Grid>
          </Grid>}

        <Divider />

        <Grid container spacing={2} className="report-select reports-header">
          <Grid item xs={8}>
            <h6>Asset Usage by Project</h6>
          </Grid>
          <Grid item xs={4} className="selector">
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Project -
                </InputLabel>
                <Select value={selectedProject} onChange={(event) => getReportsFromProject(event.target.value, toolsProjectStartDate, toolsProjectEndtDate)} disableEmpty={false} className={classes.select}  inputProps={{ 'aria-label': 'Without label' }}>
                  {projectList.map((item, key) => (
                    <MenuItem key={key} value={item}>{item}</MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
        </Grid>

        {projectAnalysis && <Grid container spacing={2} className="report-select" >
          <Grid item xs={5} className="report-table">
            <TableContainer>
                  <Table className={classes.table} aria-label="simple table" stickyHeader>
                      <TableHead className="table-header">
                          <TableRow>
                              <TableCell align="center">#</TableCell>
                              <TableCell align="left">Project Name</TableCell>
                              <TableCell align="left">Usage</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      {projectAnalysis.report.map((row, index) => (
                          <TableRow key={row.Id}>
                            <TableCell align="center" component="th" scope="row">
                                {index+1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.AssetName}
                            </TableCell>
                            <TableCell align="left">{row.count}</TableCell>
                          </TableRow>
                      ))}
                      </TableBody>
                  </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={7} className="report-charts" style={{height:"auto"}} >
            {projectAnalysis.report.length > 0 && <div className="chartcard">
              <div className="header header-1">
                    <Grid item xs={6} className="header-title">
                       Asset Usage By Project
                    </Grid>
                    <Grid item xs={6} className="header-cta">
                    <DateRangePicker
                        startDateId="toolsProjectStartDateId"
                        endDateId="toolsProjectEndDateId"
                        startDate={toolsProjectStartDate}
                        endDate={toolsProjectEndtDate}
                        customArrowIcon={<SyncAltIcon/>}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }) => {setToolsProjectStartDate(startDate);setToolsProjectEndDate(endDate)}} // PropTypes.func.isRequired,
                        focusedInput={toolsProjectFocusedInput}
                        onFocusChange={focusedInput => setToolsProjectFocusedInput(focusedInput)}
                        onClose={() => refreshReportsFromProjectData(selectedProject, toolsProjectStartDate, toolsProjectEndtDate)}
                      />
                    <div className="report-info header-card-menu" style={{justifyContent: 'flex-end'}}>
                    <ExportCSV csvData={projectAnalysis.details} fileName="Asset Usage by Projects Report" noText={true} />
                  </div>
                  </Grid>
              </div>
              <div className="content">
                <ResponsiveContainer>
                  <BarChart data={projectAnalysis.report} margin={{ top: 15, right: 10, left: 10, bottom: 15 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="AssetName" />
                    <YAxis domain={[0, projectAnalysis.report.map(el => el.count).reduce((prev, current) => Math.max(prev, current))]}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<p>Project Asset Usage</p>} />
                    <Bar dataKey="count" fill="#86BC25" label={{ fontSize: 10 }} barSize={24} minPointSize={3} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>}
          </Grid>
          </Grid>}

        <Divider />

        <Grid container spacing={2} className="user-data">
          <Grid item xs={5}>
            <div className="chartcard">
              <div className="header header-1">
                <Grid container>
                  <Grid item xs={3} className="header-title">
                    Users
                  </Grid>
                  <Grid item xs={9} className="header-cta">
                    <DateRangePicker
                        startDateId="userStartDateId"
                        endDateId="userEndDateId"
                        startDate={userStartDate}
                        endDate={userEndDate}
                        customArrowIcon={<SyncAltIcon/>}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }) => {setUserStartDate(startDate);setUserEndDate(endDate)}} // PropTypes.func.isRequired,
                        focusedInput={userFocusedInput}
                        onFocusChange={focusedInput => setUserFocusedInput(focusedInput)}
                        onClose={({startDate, endDate})=>refreshUserData(startDate, endDate)}
                      />
                      <div className="header-card-menu">
                        <ExportCSV csvData={userData} fileName="User Registration Report" noText={true} />
                      </div>
                  </Grid>
                </Grid>
              </div>
              <div className="content">
                <ResponsiveContainer>
                  <BarChart
                    data={userData}
                    margin={{ top: 15, right: 10, left: 10, bottom: 15 }}

                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Name" />
                    <YAxis />
                    <Tooltip />
                    <Legend content={<p>User Registrations</p>} />
                    <Bar dataKey="count" fill="#86BC25" label={{ fontSize: 10 }} barSize={24} minPointSize={3} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="chartcard">
              <div className="header header-2">
                <Grid container>
                  <Grid item xs={5} className="header-title" style={{'font-size': "17px"}}>
                  Assets Usage across Projects
                  </Grid>
                  <Grid item xs={7} className="header-cta">
                    <DateRangePicker
                        startDateId="toolStartDateId"
                        endDateId="toolEndDateId"
                        startDate={toolStartDate}
                        endDate={toolEndDate}
                        customArrowIcon={<SyncAltIcon/>}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }) => {setToolStartDate(startDate);setToolEndDate(endDate)}} // PropTypes.func.isRequired,
                        focusedInput={toolFocusedInput}
                        onFocusChange={focusedInput => setToolFocusedInput(focusedInput)}
                        onClose={() => refreshToolData(toolStartDate, toolEndDate)}
                      />
                      <div className="header-card-menu">
                        <ExportCSV csvData={toolDownlod} fileName="User Asset Usage Report" noText={true} />
                      </div>
                  </Grid>
                </Grid>
              </div>
              <div className="content">
              {isToolChildActive!==null?(<div style={{marginLeft:25, cursor:'pointer'}} onClick={handleToolBack}><ArrowBackIcon />  {isToolChildActive}</div>):''}
              {toolData && <ResponsiveContainer>
                  <BarChart
                    data={toolData}
                    margin={{ top: 15, right: 10, left: 10, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="8 8" />
                    <XAxis dataKey="AssetName" />
                    <YAxis domain={[0, toolData.map(el => el.count).reduce((prev, current) => Math.max(prev, current))]}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<p>Asset Activity</p>}/>
                    <Bar dataKey="count" fill="#86BC25"
                        label={{ fontSize: 8, overflowWrap: 'break-word' }}
                        minPointSize={3}
                        onClick={handleToolClick}
                        barSize={26} />
                  </BarChart>
                </ResponsiveContainer>}
              </div>
            </div>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} className='licensed-user-data reports-header'>
            <Grid item xs={12}>
              <h6 className="margin-bottom-4  ">Licensed Users Revenue</h6>
            </Grid>
            <Grid item xs={5} className="report-table">
            <TableContainer>
                  <Table className={classes.table} aria-label="simple table" stickyHeader>
                      <TableHead className="table-header">
                          <TableRow>
                              <TableCell align="center">Month</TableCell>
                              <TableCell align="left">Licenses</TableCell>
                              <TableCell align="left">Revenue (in USD)</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      {licensedUserData.map((row, index) => (
                          <TableRow key={row.Id}>
                            <TableCell align="center" component="th" scope="row">
                                {row.Month}
                            </TableCell>
                            <TableCell align="left">{row["Licenses Purchased"]}</TableCell>
                            <TableCell align="left">{row["Revenue(USD)"]}</TableCell>
                          </TableRow>
                      ))}
                      {addEmptyRows(licensedUserData)}
                      </TableBody>
                  </Table>
              </TableContainer>
          </Grid>
            <Grid item xs={7}>
                {licensedUserData.length && (
                   <div className="chartcard">
                   <div className="header header-1">
                       <Grid item xs={7} className="header-title">
                          Licensed Users Revenue (in USD)
                       </Grid>
                       <Grid item xs={5} className="header-cta">
                          <DateRangePicker
                                startDateId="revenueStartDateId"
                                endDateId="revenueEndDateId"
                                startDate={revenueStartDate}
                                endDate={revenueEndDate}
                                customArrowIcon={<SyncAltIcon/>}
                                showDefaultInputIcon
                                inputIconPosition="after"
                                isOutsideRange={() => false}
                                onDatesChange={({ startDate, endDate }) => {setRevenueStartDate(startDate);setProjectEndDate(endDate)}} // PropTypes.func.isRequired,
                                focusedInput={revenueFocusedInput}
                                onFocusChange={focusedInput => setRevenueFocusedInput(focusedInput)}
                                onClose={() => refreshLicensedUserData(revenueStartDate, revenueEndDate)}
                              />
                        <div className="report-info header-card-menu" style={{justifyContent: 'flex-end'}}>
                          <ExportCSV csvData={licensedUserData} fileName="Licensed Users Revenue Report" noText={true} />
                        </div>
                        </Grid>
                   </div>
                   <div className="content">
                     <ResponsiveContainer>
                       <BarChart data={licensedUserData} margin={{ top: 15, right: 10, left: 10, bottom: 15 }}>
                         <CartesianGrid strokeDasharray="3 3" />
                         <XAxis dataKey="Month" />
                         <YAxis domain={[0, licensedUserData.map(el => el["Revenue(USD)"]).reduce((prev, current) => Math.max(prev, current))]}/>
                         <Tooltip />
                         <Legend content={<p>Revenue Report</p>} />
                         <Bar dataKey="Revenue(USD)" name="Revenue(USD)" fill="#86BC25" label={{ fontSize: 10 }} barSize={24} minPointSize={3} />
                       </BarChart>
                     </ResponsiveContainer>
                   </div>
                 </div>
                )}
            </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12}>
          <h6 className="margin-bottom-4 margin-top-64" style={{fontWeight:"bold"}}>Cozone Adoption Report</h6>      
          </Grid>
          <Grid item xs={12}>
              <CozoneDownloadAnalytics></CozoneDownloadAnalytics>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <h6 className="margin-bottom-4 margin-top-64" style={{fontWeight:"bold"}}>Asset Wise Activity</h6>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="tool-card-container">
          <Grid item xs={4}>
            <Link   to="/activity/cozone">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #3ffac6"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Salesforce CoZone
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={lightningcozone}
                  title="Salesforce CoZone"
                  style={{width:"60px", marginRight:"20px"}}
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link   to="/activity/aura2lwc">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #DA291C"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                     Aura2LWC
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={Aura2lwc}
                  title="Aura2LWC"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link   to="/activity/valuate">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #FFCD00"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Lightning Valuate
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={valuate}
                  title="Lightning Valuate"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link   to="/activity/uitestbot">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #86BC25"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Test Bot
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={uibot}
                  title="Test Bot"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link   to="/activity/dynamicui">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #35f0ff"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Lightning Dynamic UI (Aura)
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={dynamicInt}
                  title="Lightning Dynamic UI (Aura)"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link   to="/activity/evaluator">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #0076A8"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Lightning Evaluator
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={evaluator}
                  title="Lightning Evaluator"
                />
              </Card>
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Loader show={loading} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.loading,
    login: state.login.login
  };
}

export default connect(mapStateToProps)(DataCharts);
