import React, {Component} from 'react';
import data from './DetailsPage.json';
import './DetailsPage.scss';
import  Details from '../../components/Details/Details';
import { Link } from 'react-router-dom';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';

class DetailsPage extends Component {
    state = {
        toolDetails: [],
        toolid:'',
        showLoader: true,
    }
    componentDidMount() {
        window.scrollTo(0,0);
        const toolId = window.location.search.toLowerCase().replace('?toolid=','');
        console.log('toolId:'+toolId);
        this.setState({
            toolid: toolId
          }, function() {
            
            console.log("Job's done");
          });
        
       
        this.setState({toolDetails: JSON.parse(JSON.stringify(data)).filter(item => item['toolId'] === toolId)});
    }

    render() {
        return(
            <div className="details-page-container">
                <div className="feedback-back"> 
                    
                <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
                   
                    
                <Link to={'/feedback?toolId='+this.state.toolid} className="back-button"><span>Give Feedback</span></Link>
                       
                </div>
                <Details toolDetails={this.state.toolDetails[0]}></Details>
            </div>
        );
    }
}
export default DetailsPage;