import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Route } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import Container from "@material-ui/core/Container";
import Loader from "../Loader/Loader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PreviewContainer from "./PreviewContainer";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { Link } from "react-router-dom";
import FavoriteIcon from '@material-ui/icons/Favorite';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import GetAppIcon from '@material-ui/icons/GetApp';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Tooltip } from '@material-ui/core';
import './CozoneComps.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 2),
  },
  componentTitle: {
    textAlign: "left",
    margin: theme.spacing(2),
  },
  actionContainer: {
    textAlign: "Right",
  },
  heading: {

  },
  expansionContainer:{
    marginTop: '1rem'
  },
  textContainer: {
    margin: '0.5rem 0'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CozoneComps({ componentsData, login, loading, dispatch, role, loggedInEmail, monitizationFlag }) {
  const { id } = useParams();
  const classes = useStyles();
  const [component, setComponent] = useState({});
  const [compPoints, setCompPoints] = useState('');
  const [snackopen, setSnackopen] = useState(false);
  const [pointsredeemsnackopen, setPointsredeemsnackopen] = useState(false);
  const [downloads, setDownloads] = useState(0);
  const [redeemPointsResponse,setRedeemPointsResponse] = useState();
  const [showReedem, setShowReedem] = useState(false);
  const redeemPoint1 = 'Redeem ';
  const redeemPoint2 = 'points to download this component.';
  //let showReedem = false;

  function getComponent(){
    const values = {
      componentId:component.Id,
      componentName: component.Title,
      points: component.points,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","token": login },
      body: JSON.stringify(values),
    };
    
    dispatch({ type: "LOADING", response: true });
    fetch("/api/service/componentdownload", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      dispatch({ type: "LOADING", response: false });
      console.log(data);
      if(data.data){
        setSnackopen(true);
        let link = data.data.linkToInstall;
        let win = window.open(link, '_blank');
        win.focus();
      }
    })
    .catch((err) => {
      dispatch({ type: "LOADING", response: false });
      console.log(err);
    });
  }

  function getComponentDownload(){
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json","token": login },
    };

    dispatch({ type: "LOADING", response: true });
    fetch("/api/sf/compdownlods?id="+id, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          dispatch({ type: "LOADING", response: false });
          console.log(data);
          if(data.success){
            setDownloads(data.downloads);
          }
        })
        .catch((err) => {
          dispatch({ type: "LOADING", response: false });
          console.log(err);
        });
  }

  function handleSnackClose(event, reason){
    if (reason === 'clickaway') {
      return;
    }
    setSnackopen(false);
    setPointsredeemsnackopen(false);
  }

  function redeemPoints() {
    let email;
    dispatch({ type: "LOADING", response: true });
    if(login) {
      const user = jwtDecode(login);
      email = user.email;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({"email": email, "cozoneId": id})
    };
    fetch("/api/sf/redeempoints", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      dispatch({ type: "LOADING", response: false });
      if(data){
        setRedeemPointsResponse(data);
        setPointsredeemsnackopen(true);
      }
    })
    .catch((err) => {
      dispatch({ type: "LOADING", response: false });
      console.log(err);
    });
  }

  function getCompPoints() {  
    let email;
    dispatch({ type: "LOADING", response: true });
    if(login) {
      const user = jwtDecode(login);
      email = user.email;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({"email": email, "cozoneId": id})
    };
    fetch('/api/sf/getcomppoints', requestOptions)
        .then(response => response.json())
        .then(data => 
            {
                if (data.data.length)
                    setCompPoints(data.data[0].Component_Points__c);
                    console.log(data.data[0].Component_Points__c);
            });
}

  React.useEffect(() => {
    console.log("component updated");
    if (componentsData && componentsData.length) {
      let data = componentsData.find((item) => item.Id === id);
      if (data) {
        console.log(data);
        setComponent(data);
        if(data.author_email === loggedInEmail){
          setShowReedem(false);
        } else if (monitizationFlag === 'true') {
          setShowReedem(true);
        }
      }
    }
    getComponentDownload();
    getCompPoints();
  }, [componentsData, id]);

  return (
    <Container component="main" className="component-page">
      <Loader show={loading} />
      <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success">
          Component purchased successfully !
        </Alert>
      </Snackbar>
      <Snackbar open={pointsredeemsnackopen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="info">
          {redeemPointsResponse}
        </Alert>
      </Snackbar>
      <Paper variant="outlined" square className={classes.paper}>
      <div className="feedback-back">
      <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
      <Link to={'/feedback?component='+component.Title+'&sfid='+id} className="back-button"><span>Give Feedback</span></Link>
      </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.componentTitle}
                >
                  <span className="text-level-7">{component.Title}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} className="header-info">
                <Chip
                  avatar={
                    <Avatar className="avatar">
                      <PersonOutlineOutlinedIcon />
                    </Avatar>
                  }
                  label={component.Author}
                  clickable
                  variant="outlined"
                  containerElement="a"
                  className="clickable text-small-2"
                  href={"mailto:" + component.author_email__c}
                />
                <div className="likes">
                <Tooltip title='# of Likes'>
                  <div className="likes-icon">
                    {component.likes  && <FavoriteIcon className="likes-icon"/> }
                  </div>
                  </Tooltip>
                  <div class="likes-count">
                      {component.likes && component.likes.length}
                  </div>
                </div>
                <div className="downloads">
                  <div className="download-icon">
                    {downloads>-1 && <GetAppIcon/>}
                  </div>
                  <Tooltip title='# of Downloads'>
                  <div className="download-count">
                    
                      {downloads>-1 && downloads }
                    
                  </div>
                  </Tooltip>
                </div>
                { showReedem &&  <span>
                <div className="pricetag">
                  <div className="pricetag-icon">
                    <Tooltip title = {redeemPoint1 + ' ' + compPoints + ' ' + redeemPoint2}>
                      {compPoints>-1 && <EmojiEventsIcon/>}
                    </Tooltip>
                  </div>
                  <div className="pricetag-value">
                    {compPoints>-1 && compPoints }
                  </div>
                </div>
                </span> }
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.actionContainer}
                alignItems="flex-end"
                justify="flex-end"
                textAlign="right"
              >
                {role && (role==='admin'||role==='licensed') && (
                <div>
                <button
                  className="btn-cmp-action"
                  onClick={()=>getComponent()}
                >
                  Get Component
                  <CloudDownloadIcon className="margin-left-8"/>
                </button>                
                { showReedem &&  <span>
                  <Tooltip title = {redeemPoint1 + ' ' + compPoints + ' ' + redeemPoint2}>
                    <button
                    className="btn-points-action"
                    onClick={()=>redeemPoints()}
                    >
                    Redeem Points
                    </button>
                  </Tooltip>
                </span> 
                }
              </div>)}
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.textContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  dangerouslySetInnerHTML={{ __html: component.description }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: component.Key_Component_Highlights,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.expansionContainer}>
          <Grid item xs={12}>
            {component.type === "component" || component.type === "LWC" ? (
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <div className={classes.column}>
                    <Typography variant="h5" className={classes.heading}>Preview</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  {component && (
                    <PreviewContainer
                      compName={component.Name}
                      bundleId={component.Id}
                    />
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Paper>
      </div>
    </Container>
  );
}

CozoneComps.propTypes = {
  
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.loading,
    componentsData: state.sidenav.components,
    login: state.login.login,
    role: state.login.role,
    loggedInEmail: state.login.email,
    monitizationFlag: state.dashboard.monitizationFlag
  };
}

export default connect(mapStateToProps)(CozoneComps);
