import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const devEfficay = {
    'Low': 10,
    'Medium': 12,
    'High': 15
};

const qualityEfficacy = {
    'Low': 10,
    'Medium': 12,
    'High': 15
};

class CozoneDownloadAnalytics extends Component {

    constructor(){
        super();
        this.state = { records: [] };
        this.processData = this.processData.bind(this);
    }

    componentDidMount() {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "token": this.props.login,
            },
        };
        this.props.dispatch({ type: "LOADING", response: true });
        fetch("/api/cozoneadoption/report", requestOptions)
        .then((response) => response.json())
        .then((data) => {

            this.props.dispatch({ type: "LOADING", response: false });
            if(data){
                console.log(data);
                this.processData(data);
             }
        })
        .catch((err) => {
            console.log(err);
            this.props.dispatch({ type: "LOADING", response: false });
        });
    }

    processData(data) {
        try{
            if (data) {
                let map = {};
                let records = [];
                for (let i = 0; i < data.length; i++) {
                    let complexity = '';
                    if (data[i].Value === '5') {
                        complexity = 'Low';
                    } else if (data[i].Value === '10') {
                        complexity = 'Medium'
                    } else {
                        complexity = 'High';
                    }
                    console.log(complexity);
                    if (map[data[i].Project]) {
                        if (map[data[i].Project][complexity]) {
                            map[data[i].Project][complexity].count = map[data[i].Project][complexity].count + 1;
                        } else {
                            map[data[i].Project][complexity] = {
                                count: 1,
                                User: data[i].User,
                                Project: data[i].Project,
                                Tool: data[i].AssetName,
                                TrackingType: data[i].TrackingType,
                                Point: data[i].Value
                            };
                        }
                    } else {
                        map[data[i].Project] = {
                            [complexity]: {
                                count: 1,
                                User: data[i].User,
                                Project: data[i].Project,
                                Tool: data[i].AssetName,
                                TrackingType: data[i].TrackingType,
                                Point: data[i].Value
                            }
                        };
                    }
                }
                
                if(map) {
                    for(let project in map) {
                        for(let complexity in map[project]){
                        records.push({
                            count: map[project][complexity].count,
                            "User": map[project][complexity].User,
                            "Project": map[project][complexity].Project,
                            "complexity": complexity,
                            "Points": map[project][complexity].Point,
                            "DevEfficacy": devEfficay[complexity],
                            "QualityEfficacy": qualityEfficacy[complexity],
                        })
                        }
                    }
                }
                this.setState({records: records});
            }
        } catch(e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div>
            <TableContainer>
                <Table className="" aria-label="simple table" stickyHeader>
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="left">Project</TableCell>
                            <TableCell align="left">Count</TableCell>
                            <TableCell align="left">Complexity</TableCell>
                            <TableCell align="left">Std hrs</TableCell>
                            <TableCell align="left">Dev efficiency</TableCell>
                            <TableCell align="left">Dev hrs saved</TableCell>
                            <TableCell align="left">Quality %</TableCell>
                            <TableCell align="left">Quality hrs saved</TableCell>
                            <TableCell align="left">Total hrs saved</TableCell>
                            <TableCell align="left">Total project hrs</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.records.length && this.state.records.map((row, index) => {
                            return <TableRow key={row.Id}>
                                <TableCell align="center" component="th" scope="row">
                                    {index}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.Project}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.count}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.complexity}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.Points}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.DevEfficacy}%
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {
                                        Math.round(row.count * (row.Points/100)*row.DevEfficacy)
                                    }
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.QualityEfficacy}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {
                                        Math.round(row.count * (row.Points/100)*row.DevEfficacy) * row.QualityEfficacy/100
                                    }
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {
                                        Math.round(row.count * (row.Points/100)*row.DevEfficacy) +
                                        Math.round(row.count * (row.Points/100)*row.DevEfficacy) * row.QualityEfficacy/100
                                    }
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {index}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      loading: state.loading,
      login: state.login.login
    };
}

export default connect(mapStateToProps)(CozoneDownloadAnalytics);
