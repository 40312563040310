import React, { Component } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './TrainingContent.scss';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';

export default class TrainingContent extends Component {
      download(path,name){
        axios({
            url: path,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });
    }

    render() {
        return (
            <div className="training-content">
            <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
                <Card className="doc-card">
                    <CardHeader
                        className="mainTitle sub-heading"
                        title="Best Practices"
                        subtitle=""
                        actasexpander={false}
                        showExpandableButton={false}
                    />
                    <hr/>
                    <CardContent className="body-white">
                        <div className="slds-grid">
                            <div className="slds-col text-level-8">Lightning Best Practices Document (Winter 2018)</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                onClick={()=>this.download("/docs/LightningBestPractices_v2.pdf","LightningBestPractices_v2.pdf")}>Download</Link>
                            </div>
                        </div>
                    </CardContent>
                </Card>
               <br/>
               <Card className="doc-card">
                    <CardHeader
                        className="mainTitle sub-heading"
                        title="Point of Views"
                        subtitle=""
                        actasexpander={false}
                        showExpandableButton={false}
                    />
                    <hr/>
                    <CardContent className="body-white">
                        <div className="slds-grid">
                            <div className="slds-col text-level-8">Salesforce LWC Implementation - Point of View </div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                onClick={()=>this.download("/docs/Salesforce_LWC_Implementations_Point_Of_View_v1.0.pdf","Salesforce_LWC_Implementations_Point_Of_View_v1.0.pdf")}>Download</Link>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <br/>
                <Card className="doc-card">
                    <CardHeader
                        className="mainTitle"
                        title="Estimation Model"
                        subtitle=""
                        actasexpander={false}
                        showExpandableButton={false}
                    />
                    <hr/>
                    <CardContent className="body-white">
                        <div className="slds-grid  slds-p-bottom--small">
                            <div className="slds-col text-level-8">Lightning Estimation Model</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                    onClick={()=>this.download("/docs/Salesforce_Lightning_Estimation_Model_v1.xlsx","Salesforce_Lightning_Estimation_Model_v1.xlsx")}>Download</Link>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <br/>
                <Card className="doc-card">
                    <CardHeader
                        title="Aura Training Docs"
                        subtitle=""
                        className="mainTitle"
                        actasexpander={false}
                        showExpandableButton={false}
                    />
                    <hr/>
                    <CardContent className="body-white">
                        <div className="slds-grid  slds-p-bottom--small">
                            <div className="slds-col text-level-8">Lightning Basics</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                    onClick={()=>this.download("/docs/LightningBasics.zip","LightningBasics.zip")}>Download</Link>
                            </div>
                        </div>
                        <div className="slds-grid  slds-p-bottom--small">
                            <div className="slds-col text-level-8">Lightning Design Systems</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                    onClick={()=>this.download("/docs/LightningDesignSystems.zip","LightningDesignSystems.zip")}>Download</Link>
                            </div>
                        </div>
                        <div className="slds-grid  slds-p-bottom--small">
                            <div className="slds-col text-level-8">Lightning Aura</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                    onClick={()=>this.download("/docs/LightningAura.zip","LightningAura.zip")}>Download</Link>
                            </div>
                        </div>
                        <div className="slds-grid slds-p-bottom--small">
                            <div className="slds-col text-level-8">Lightning Advanced</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                    onClick={()=>this.download("/docs/LightningAdvanced.zip","LightningAdvanced.zip")}>Download</Link>
                            </div>
                        </div>
                    </CardContent>
               </Card>
                <br/>
                <Card className="doc-card">
                    <CardHeader
                        title="LWC Training Docs"
                        subtitle=""
                        className="mainTitle"
                        actasexpander={false}
                        showExpandableButton={false}
                    />
                    <hr/>
                    <CardContent className="body-white">
                        <div className="slds-grid  slds-p-bottom--small">
                            <div className="slds-col text-level-8">Lightning LWC Basics</div>
                            <div className="slds-col link">
                                <Link variant="contained" to={"#"}
                                    onClick={()=>this.download("/docs/LightningBasics.zip","LightningBasics.zip")}>Download</Link>
                            </div>
                        </div>
                    </CardContent>
                </Card>
               </div>
        );
    }
}

