const dashboard = (state = {}, action) => {
    switch (action.type) {
      case 'GET_DASHBOARD':
        return {
            ...state,
            dashboard: action.response
        };
      case 'MONTIZATION_FLAG':
        return {
          ...state,
          monitizationFlag: action.response
        };
      default:
        return state;
    }
  }
  
  export default dashboard;