function captalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  export function filterBy(...props) {
    let checkInMultipleValues = false;
  
    if (Array.isArray(props[2])) {
      checkInMultipleValues = true;
    }
    return props[0].filter(item => {
      if (checkInMultipleValues)
        return (
          ~props[2].indexOf(item[props[1]]) ||
          ~props[2].indexOf(captalize(item[props[1]]))
        );
      else
        return (
          item[props[1]] == props[2] || item[captalize(props[1])] == props[2]
        );
    });
  }