import React from 'react';
import {useHistory, Link} from 'react-router-dom';
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LightningLogo from '../../assets/images/lightning_cozone.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import Loader from '../Loader/Loader';
import { useFormik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './PurchaseLincense.scss';

import jwtDecode from 'jwt-decode';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/" href="#">
        Lightning CoZone
      </Link>{' '}
      {new Date().getFullYear() }
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function PurchaseLincense({ dispatch, user, role }) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [userDetails, setUserDetails] = React.useState({});

  React.useEffect(() => {
    console.log("in use effect"+user);
    if(user) {
      console.log(jwtDecode(user));
      setUserDetails(jwtDecode(user));
      console.log(userDetails);
    }
  },[user]);

  const handleCloseAndRedirectToLogin = () => {
    setOpen(false);
    history.push("/dashboard");
  };

  function handleRegister(values) {
    console.log(values);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    };
    setError('');
    fetch('/api/register', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLoading(false);
        if(data.success){
          setOpen(true);
        }else if(!data.success && 'message' in data){
          setError(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        if(err && 'message' in err){
          setError(err.message);
        }
        setLoading(false);
      });
  }

  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.projectName) {
      errors.projectName = 'Required';
    }
    if (!values.usiGDMName) {
      errors.usiGDMName = 'Required';
    }
    if (!values.usiGDMEmail) {
      errors.usiGDMEmail = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.usiGDMEmail)) {
      errors.usiGDMEmail = 'Invalid email address';
    }
    if (!values.usGDMName) {
      errors.usGDMName = 'Required';
    }
    if (!values.usGDMEmail) {
      errors.usGDMEmail = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.usGDMEmail)) {
      errors.usGDMEmail = 'Invalid email address';
    }
    if (!values.usiPPMD) {
      errors.usiPPMD = 'Required';
    }
    if (!values.partner) {
      errors.partner = 'Required';
    }
    if (!values.wbsCode) {
      errors.wbsCode = 'Required';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      name:userDetails.name,
      email: userDetails.email,
      projectName: '',
      usiGDMName: '',
      usiGDMEmail: '',
      usGDMName: '',
      usGDMEmail: '',
      usiPPMD: '',
      partner: '',
      wbsCode: ''
    },
    enableReinitialize:true,
    validate,
    onSubmit: values => {
      console.log("on submit");
      setLoading(true);
      handleRegister(values);
    },
  });

  return (
    <Container component="main" maxWidth="md">
      <Loader show={loading} />
      <div className="purchase-license">
        <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
        <h1 class="text-bold text-level-10">Purchase License</h1>
      </div>
      <Dialog
        open={open}
        onClose={handleCloseAndRedirectToLogin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Request Successfull"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your Request for License Purchase has been created. An email has been sent to USI GDM with the next steps.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAndRedirectToLogin} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.paper}>
        {/*<Grid container justify="center">
          <div className={classes.deloitteAvatar}>
              <img src={LightningLogo} height="150"/>
          </div>
        </Grid>*/}
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                variant="outlined"
                fullWidth
                id="firstName"
                label="Name"
                value={formik.values.name}
                defaultValue={formik.values.name}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={formik.values.email}
                defaultValue={formik.values.email}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="projectName"
                label="Project Name"
                name="projectName"
                autoComplete="projectName"
                onChange={formik.handleChange}
                value={formik.values.projectName}
                error={Boolean(formik.touched.projectName && formik.errors.projectName) }
                helperText={(formik.touched.projectName && formik.errors.projectName) && formik.errors.projectName}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="wbsCode"
                label="WBS Code"
                name="wbsCode"
                autoComplete="wbsCode"
                onChange={formik.handleChange}
                value={formik.values.wbsCode}
                error={Boolean(formik.touched.wbsCode && formik.errors.wbsCode) }
                helperText={(formik.touched.wbsCode && formik.errors.wbsCode) && formik.errors.wbsCode}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="usiGDMName"
                label="USI GDM Name"
                name="usiGDMName"
                autoComplete="usiGDMName"
                onChange={formik.handleChange}
                value={formik.values.usiGDMName}
                error={Boolean(formik.touched.usiGDMName && formik.errors.usiGDMName) }
                helperText={(formik.touched.usiGDMName && formik.errors.usiGDMName) && formik.errors.usiGDMName}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="usiGDMEmail"
                label="USI GDM Email"
                name="usiGDMEmail"
                autoComplete="usiGDMEmail"
                onChange={formik.handleChange}
                value={formik.values.usiGDMEmail}
                error={Boolean(formik.touched.usiGDMEmail && formik.errors.usiGDMEmail) }
                helperText={(formik.touched.usiGDMEmail && formik.errors.usiGDMEmail) && formik.errors.usiGDMEmail}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="usGDMName"
                label="US GDM Name"
                name="usGDMName"
                autoComplete="usGDMName"
                onChange={formik.handleChange}
                value={formik.values.usGDMName}
                error={Boolean(formik.touched.usGDMName && formik.errors.usGDMName) }
                helperText={(formik.touched.usGDMName && formik.errors.usGDMName) && formik.errors.usGDMName}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="usGDMEmail"
                label="US GDM Email"
                name="usGDMEmail"
                autoComplete="usGDMEmail"
                onChange={formik.handleChange}
                value={formik.values.usGDMEmail}
                error={Boolean(formik.touched.usGDMEmail && formik.errors.usGDMEmail) }
                helperText={(formik.touched.usGDMEmail && formik.errors.usGDMEmail) && formik.errors.usGDMEmail}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="usiPPMD"
                label="USI PPMD"
                name="usiPPMD"
                autoComplete="usiPPMD"
                onChange={formik.handleChange}
                value={formik.values.usiPPMD}
                error={Boolean(formik.touched.usiPPMD && formik.errors.usiPPMD) }
                helperText={(formik.touched.usiPPMD && formik.errors.usiPPMD) && formik.errors.usiPPMD}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="partner"
                label="Partner"
                name="partner"
                autoComplete="partner"
                onChange={formik.handleChange}
                value={formik.values.partner}
                error={Boolean(formik.touched.partner && formik.errors.partner) }
                helperText={(formik.touched.partner && formik.errors.partner) && formik.errors.partner}
                />
            </Grid>

          </Grid>
          {error && <Typography component="h3" variant="body1" style={{'textAlign':'center'}}>
                        <span className="text-small-2" style={{color:'#da291c'}}>{error}</span>
                    </Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            >
            Purchase License
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
      user: state.login.login,
      role: state.login.role
  }
}

export default connect(mapStateToProps)(PurchaseLincense);
