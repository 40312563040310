import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansionDetails: {
      display: "inherit"
  },
  searchComponent: {
    width: '100%'
  }
}));



export default function SideNavList({ title, components, close }) {
  const classes = useStyles();
  const history = useHistory();
  const [searchVal, setSearchVal] = React.useState('');

  function navigateToComp(id){
    console.log(id);
    close();
    history.push('/component/'+id);
  }

  function searchComponents(event) {
    let keyword = event.target.value;
    setSearchVal(keyword);
  }


  return (
    <div className="expansion-panel" style={{width:'100%'}}>
      <ExpansionPanel style={{boxShadow: "initial"}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <TextField id="standard-search" className={classes.searchComponent} label="Search" variant="outlined" type="search" onChange={(e) => searchComponents(e)} />
          <List dense={true}>
          {
            (components || []).filter((item) => {
              if (searchVal === '') {
                return item;
              } else if (item.Title.toLowerCase().includes(searchVal.toLowerCase())) {
                return item;
              }
            }).map((item) => {
              return (
                <div>
                <ListItem disableGutters={true}>
                  <ListItemText
                    primary={item.Title}
                    onClick={() => navigateToComp(item.Id)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="view">
                    {
                      (item.type==='component'||item.type==='LWC')? <VisibilityIcon /> : <VisibilityOffIcon/>
                    }
                        
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                </div>
              )
            })
          }
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}


SideNavList.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired, 
}