import React, { useEffect } from "react";
import { Component, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader/Loader";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';

import moment from 'moment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

// Styles
import "./DataCharts.scss";
import 'react-dates/lib/css/_datepicker.css';

import lightningcozone from "../../assets/images/Salesforce_Cozone.png";
import Aura2lwc from "../../assets/images/AURA2LWC.png";
import dynamicInt from "../../assets/images/LDinterface.png";
import evaluator from "../../assets/images/LIGHTNINGEVALUATOR.png";
import valuate from "../../assets/images/LightningValuate.png";
import uibot from "../../assets/images/UI_Test_Bot.png";

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ExportCSV } from "../ExportCSV/ExportCSV";
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  cardroot: {
    display: "flex",
    minHeight: 120,
    justifyContent: "space-between",
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 12,
  },
  content: {
    flex: "1 0 auto",
    alignItems: "center",
  },
  cover: {
    width: 100,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 120,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const UserCharts = ({ loading, dispatch,login }) => {
  const [toolData, setToolData] = useState([]);
  const [toolActualData, setToolActualData] = useState([]);
  const [toolDownlod, setToolDownload] = useState([]);
  const [isToolChildActive, setIsToolChildActive] = useState(null);

  const today = new Date();
  const prevday = today.setDate(today.getDate() - 7);  
  const [toolStartDate, setToolStartDate] = useState(moment().day(-30));
  const [toolEndDate, setToolEndDate] = useState(moment());
  const [toolFocusedInput, setToolFocusedInput] = useState(null);
  const [selectedRange, setSelectedRange] = useState("1w");


  function getToolData(start, end) {
    const values = {
      startDate: start.toISOString(),
      endDate: end.toISOString(),
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","token": login },
      body: JSON.stringify(values),
    };
    dispatch({ type: "LOADING", response: true });
    fetch("/api/admin/report/toolanalysis", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "LOADING", response: false });
        if(data.success){
          if('data' in data){
            const toolsMap = {
              'Test Bot':'TestBot',
              'Lightning Valuate':'Valuate',
              'Lightning Evaluator':'Evaluator',
              'Aura2LWC':'Aura2LWC',
              'Lightning Dynamic UI (Aura)':'DynamicUI',
              'Salesforce CoZone':'CoZone'
            }
            const result = data.data.report;
            for(let i=0;i<result.length;i++){
              let toolName = result[i].AssetName;
              if(toolName && toolName in toolsMap){
                result[i].AssetName = toolsMap[toolName];
              }
            }
            setToolData(result);
            setToolActualData(result);
            setToolDownload(data.data.details);
          }
        }
      })
      .catch((err) => {
        dispatch({ type: "LOADING", response: false });
        console.log(err);
      });
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
          <div className="custom-tooltip">
            <p className="label">{`${label} : ${payload[0].value}`}</p>
            <p className="intro">{getLabels(label)}</p>
          </div>
      );
    }
    return null;
  }

  function getLabels(label){
    if(label === 'TestBot'){
        return 'No of Test cases executed';
    } else if (label === 'Valuate') {
      return 'Valuate Component report'
    } else if (label === 'Evaluator') {
      return 'No of Org Evaluation reports';
    } else if(label === 'Aura2LWC') {
      return 'No of Components converted from Aura to LWC';
    } else if(label === 'DynamicUI'){
      return 'No of Dynamic components generated';
    } else if(label === 'CoZone'){
      return 'No of reusable components downloaded';
    }
  }

  function refreshToolData(startDate, endDate) {
    if(startDate && endDate){
      getToolData(toolStartDate, toolEndDate);
    }
  }

  const handleToolClick = (data, index) => {
    if(('count' in data) && data.count >0 && ('children' in data)){
      setIsToolChildActive(data.AssetName);
      setToolData(data.children)
    }
  }

  const handleToolBack = () => {
    setIsToolChildActive(null);
    setToolData(toolActualData);
  }

  const classes = useStyles();
  useEffect(() => {
    dispatch({ type: "LOADING", response: true });
    getToolData(toolStartDate, toolEndDate);
  }, [selectedRange]);

  return (
    <Container omponent="main" className="reports">
      <Loader show={loading} />
      <div className={classes.paper}>
      <Link to="/dashboard" className="back-button"><BackButton></BackButton><div>Back to Dashboard</div></Link>
        <Grid container spacing={3}>
          <Grid item xs={12}>
           <Typography component="h1" variant="h5" style={{marginBottom:"20px"}}>
              User Tool Reports
            </Typography>
          </Grid>
          
          <Grid item xs={12}>
            <div className="chartcard">
              <div className="header header-2">
                <Grid container>
                  <Grid item xs={3} className="header-title">
                    Asset Usage
                  </Grid>
                  <Grid item xs={9} className="header-cta">
                    <DateRangePicker
                        startDateId="toolStartDateId"    
                        endDateId="toolEndDateId" 
                        startDate={toolStartDate} 
                        endDate={toolEndDate} 
                        customArrowIcon={<SyncAltIcon/>}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }) => {setToolStartDate(startDate);setToolEndDate(endDate)}} // PropTypes.func.isRequired,
                        focusedInput={toolFocusedInput} 
                        onFocusChange={focusedInput => setToolFocusedInput(focusedInput)} 
                        onClose={({startDate, endDate})=>refreshToolData(startDate, endDate)}
                      />
                      <div className="header-card-menu">
                          <ExportCSV csvData={toolDownlod} fileName="User Asset Usage Report" noText={true} />    
                      </div>
                  </Grid>
                </Grid>
              </div>
              <div className="content">
              {isToolChildActive!==null?(<div style={{marginLeft:25, cursor:'pointer'}} onClick={handleToolBack}><ArrowBackIcon />  {isToolChildActive}</div>):''}
              {toolData.length && <ResponsiveContainer>
                  <BarChart
                    data={toolData}
                    margin={{ top: 15, right: 10, left: 10, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="8 8" />
                    <XAxis dataKey="AssetName" />
                    <YAxis domain={[0, toolData.map(el => el.count).reduce((prev, current) => Math.max(prev, current))]}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<p>Asset Activity</p>}/>
                    <Bar dataKey="count" fill="#86BC25"
                        label={{ fontSize: 8, overflowWrap: 'break-word' }}
                        minPointSize={3}
                        onClick={handleToolClick}
                        barSize={26} />
                  </BarChart>
                </ResponsiveContainer>}
              </div>
            </div>
          </Grid>
        </Grid>
      
        <Grid container>
          <Grid item>
            <h6 className="margin-bottom-4 margin-top-64" style={{fontWeight:"bold"}}>Asset Wise Activity</h6>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="tool-card-container">
          <Grid item xs={4}>
            <Link  to="/activity/cozone">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #3ffac6"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Salesforce CoZone
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={lightningcozone}
                  title="Salesforce CoZone"
                  style={{width:"60px", marginRight:"20px"}}
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link  to="/activity/aura2lwc">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #DA291C"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Aura2LWC
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={Aura2lwc}
                  title="Aura2LWC"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link  to="/activity/valuate">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #FFCD00"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Lightning Valuate
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={valuate}
                  title="Lightning Valuate"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link  to="/activity/uitestbot">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #86BC25"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Test Bot
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={uibot}
                  title="Test Bot"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link  to="/activity/dynamicui">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #35f0ff"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Lightning Dynamic UI (Aura)
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={dynamicInt}
                  title="Lightning Dynamic UI (Aura)"
                />
              </Card>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link  to="/activity/evaluator">
              <Card className={classes.cardroot} style={{borderTop: "6px solid #0076A8"}}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <div
                      className="toollabel text-level-7"
                    >
                      Lightning Evaluator
                    </div>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={evaluator}
                  title="Lightning Evaluator"
                />
              </Card>
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Loader show={loading} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.loading,
    login: state.login.login
  };
}

export default connect(mapStateToProps)(UserCharts);
