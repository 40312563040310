import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { Container, Typography } from '@material-ui/core';
import Loader from '../Loader/Loader';
import { connect } from 'react-redux';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        width: '100%',
        border: '1px solid #D3D8E8',
        background:'#fff',
        boxShadow: '0 2px rgba(0,0,0,0.19)',
    },
}));

function MyDownloads({ match, dispatch, user, loading }) {
  const classes = useStyles();
  const toolName = match.params.toolname;
  const [downloads, setDownloads] = useState([]);

  function makeDate(createdDate) {
    let date = moment(createdDate);
    return date.format('Do MMM, YYYY');
  }

  function getMyDownloadsData() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user },
      };
      dispatch({ type: 'LOADING', response: true });
      fetch('/api/tools/mydownloadcomponent', requestOptions)
      .then(response => response.json())
      .then(data => {
          if (data) {
              console.log(data);
              setDownloads(data);
              dispatch({ type: 'LOADING', response: false });
          }
        })
        .catch(err =>{
            console.log(err);
            dispatch({ type: 'LOADING', response: false });
        })
  };

  useEffect(() => {
    getMyDownloadsData();
  },[]);

  return (
    <Container component="main" className="tools-activity">
        <Loader show={loading} />
        <div className={classes.paper}>
        <Link to="/dashboard" className="back-button"><BackButton></BackButton><div>Back to Dashboard</div></Link>
            {downloads.length ? (<div>
            <Typography component="h1" variant="h5" style={{marginBottom:"20px"}}>
                My Downloads
            </Typography>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Component Name</TableCell>
                            <TableCell align="left">Downloaded Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {downloads.map((row, index) => (
                        <TableRow key={row.Id}>

                        <TableCell component="th" scope="row" align="center">
                            {index+1}
                        </TableCell>
                        <TableCell align="left">
                           <Link to={'/component/'+row.ComponentId}>{row.ComponentName}</Link>
                        </TableCell>
                        <TableCell align="left">{makeDate(new Date(row.CreatedDate))}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>) : (
            <Typography component="h6" variant="h6" style={{marginBottom:"20px"}}>
               You haven't made any downloads yet. :(
            </Typography>
            )}
        </div>
    </Container>
  );

                    }
function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.login.login
    }
}

export default connect(mapStateToProps)(MyDownloads);
