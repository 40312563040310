import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import Loader from '../Loader/Loader';
import CardMedia from '@material-ui/core/CardMedia';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './RecentActivities.scss';
import { ReactComponent as BackButton } from '../../assets/svg/back-icon.svg';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    border: '1px solid #D3D8E8',
    background:'#fff',
    boxShadow: '0 2px rgba(0,0,0,0.19)',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const RecentActivities = ({ login, loading, dispatch }) => {

  const classes = useStyles();
  const token = login && login.token ? login.token : localStorage.getItem('usertoken');
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);

  
  function makeDate(createdDate) {
    let date = moment(createdDate);
    return date.format('Do MMM, YYYY');
  }
 
  function getAnalyticsAndOrders() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'token': token
      })
    };
    dispatch({ type: 'LOADING', response: true });
    fetch('/api/orders/get', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          console.log('rows2:' + JSON.stringify(data));
          setRows2(data);
        }
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: 'LOADING', response: false });
      });

    fetch('/api/analytics/get', requestOptions)
      .then(response => response.json())
      .then(data => {

        if (data) {
          console.log('rows:' + JSON.stringify(data));
          setRows(data);
        }

      })
      .catch(err => {
        console.log(err);
        dispatch({ type: 'LOADING', response: false });
      });
      dispatch({ type: 'LOADING', response: false });
  };

  React.useEffect(() => {
    getAnalyticsAndOrders();
  }, []);

  return (
    <Container component="main" className="recent-activity">
      <Loader show={loading} />
      <div className={classes.paper}>
      <Link to="/dashboard" className="back-button"><BackButton></BackButton><span>Back to Dashboard</span></Link>
        <Grid item xs={12}>
        <Typography component="h6" variant="h6" style={{marginBottom:"20px"}}>
            My Recent Activties
        </Typography>

        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className="table-header">
              <TableRow>
                <TableCell><b>Tracking Type</b></TableCell>
                <TableCell align="left"><b>Component</b></TableCell>
                <TableCell align="left"><b>Name</b></TableCell>
                <TableCell align="left"><b>Value</b></TableCell>
                <TableCell align="left"><b>Asset Name</b></TableCell>
                <TableCell align="left"><b>Created Date</b></TableCell>
                <TableCell align="left"><b>Modified Date</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.TrackingType}
                  </TableCell>
                  <TableCell align="left">{row.Component}</TableCell>
                  <TableCell align="left">{row.Name}</TableCell>
                  <TableCell align="left">{row.Value}</TableCell>
                  <TableCell align="left">{row.AssetName}</TableCell>
                  <TableCell align="left">{makeDate(new Date(row.CreatedDate))}</TableCell>
                  <TableCell align="left">{makeDate(new Date(row.ModifiedDate))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>
      <div className={classes.reportSummary}>
        <div className="report-summary-title">My Recent Orders
    </div>

        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><b>Item Name</b></TableCell>
                <TableCell align="left"><b>Component</b></TableCell>

                <TableCell align="left"><b>Status</b></TableCell>
                <TableCell align="left"><b>Config Limit Name</b></TableCell>
                <TableCell align="left"><b>Asset Name</b></TableCell>
                <TableCell align="left"><b>Billing Amount</b></TableCell>
                <TableCell align="left"><b>Created Date</b></TableCell>
                <TableCell align="left"><b>Modified Date</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.ItemName}
                  </TableCell>
                  <TableCell align="left">{row.ComponentName}</TableCell>

                  <TableCell align="left">{row.Status}</TableCell>
                  <TableCell align="left">{row.ConfigLimitName}</TableCell>
                  <TableCell align="left">{row.AssetName}</TableCell>
                  <TableCell align="left">{row.BillingAmount}</TableCell>
                  <TableCell align="left">{row.CreatedDate}</TableCell>
                  <TableCell align="left">{row.UpdatedDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    </Container>
  );
};


function mapStateToProps(state) {
  return {
    loading: state.loading,
    login: state.login.login
  }
}

export default connect(mapStateToProps)(RecentActivities);