import React from 'react';
import {useHistory, Link} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LightningLogo from '../../assets/images/lightning_cozone.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loader from '../Loader/Loader';
import { useFormik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/" href="#">
        Lightning CoZone
      </Link>{' '}
      {new Date().getFullYear() }
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Register() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleCloseAndRedirectToLogin = () => {
    setOpen(false);
    history.push("/");
  };

  function handleRegister(values) {
    console.log(values);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    };
    setError('');
    fetch('/api/simpleregister', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLoading(false);
        if(data.success){
          setOpen(true);
        }else if(!data.success && 'message' in data){
          setError(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        if(err && 'message' in err){
          setError(err.message);
        }
        setLoading(false);
      });
  }

  const validate = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Required';
    } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.password)) {
      /*Password must be of 6 to 16 digits with atleast one number and atleast a special character*/
      errors.password = 'Password must be of 6 to 16 digits with atleast one number and atleast a special character.';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      password: '',
      projectName: '',
    },
    validate,
    onSubmit: values => {
      setLoading(true);
      handleRegister(values);
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Loader show={loading} />
      <Dialog
        open={open}
        onClose={handleCloseAndRedirectToLogin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Successfully Registered"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your account has been created. Please login with your credentials used while registring.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAndRedirectToLogin} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.paper}>
        <Grid container justify="center">
          <div className={classes.deloitteAvatar}>
              <img src={LightningLogo} height="120"/>
          </div>
        </Grid>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                size="small"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                error={Boolean(formik.touched.firstName && formik.errors.firstName) }
                helperText={(formik.touched.firstName && formik.errors.firstName) && formik.errors.firstName}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                size="small"
                onChange={formik.handleChange}                
                value={formik.values.lastName}
                error={Boolean(formik.touched.lastName && formik.errors.lastName) }
                helperText={(formik.touched.lastName && formik.errors.lastName) && formik.errors.lastName}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                size="small"
                onChange={formik.handleChange}                
                value={formik.values.email}
                error={Boolean(formik.touched.email && formik.errors.email) }
                helperText={(formik.touched.email && formik.errors.email) && formik.errors.email}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                size="small"
                onChange={formik.handleChange}                
                value={formik.values.password}
                error={Boolean(formik.touched.password && formik.errors.password) }
                helperText={(formik.touched.password && formik.errors.password) && formik.errors.password}
                />
            </Grid>
          </Grid>
          {error && <Typography component="h3" variant="body1" style={{'textAlign':'center'}}>
                        <span className="text-small-2" style={{color:'#da291c'}}>{error}</span>
                    </Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/" className="help-auth-text">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}