import React, {Component} from 'react';
import './Details.scss';
import Icon from '@material-ui/core/Icon';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import Grid from '@material-ui/core/Grid';

var images = require.context('../../assets/images/', true);

export default class Details extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.toolDetails && <div className="details-container">
                    <div className="details-data">
                        <div className="panel-container-1">
                            <div className="panel-heading">
                                <Grid container spacing={2}>
                                    <Grid item xs={1}>
                                        <div className="logo">
                                            <img className='icon' src={images(`./${this.props.toolDetails['toolIcon']}`)} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <h1 className="text-bold text-level-10 justify-center">
                                            {this.props.toolDetails['toolName']}
                                        </h1>
                                    </Grid>
                                </Grid>

                            </div>
                            <div className="inner-panel text-small-2">
                                {this.props.toolDetails['toolDesc']}
                                <div className="inner-panel-link"><a target="_blank" rel="noopener noreferrer" href={this.props.toolDetails['toolLink']}>{this.props.toolDetails['toolLink']}</a></div>
                            </div>
                        </div>
                        <div className="panel-containers">
                            {this.props.toolDetails['toolInfo'].map((toolInfo, index) =>
                                <div className="panel-container" key={index}>
                                    <div className="text-bold text-level-12 panel-header">
                                        {toolInfo['toolInfoHeading']}
                                    </div>
                                    <div className="panel-content">
                                        {toolInfo['toolInfoDetails'] && <ul className="panel-body text-small-2">
                                            {toolInfo['toolInfoDetails'].map((toolInfoDetail, detailIndex) =>
                                                <div className="tool-info-details">
                                                    <div><CheckIcon /></div>
                                                    <div key={detailIndex}>{toolInfoDetail}</div>
                                                </div>
                                            ) }
                                        </ul>}
                                        {toolInfo['toolInfoObjDetails'] && <ul className="panel-body text-level-8">
                                            {toolInfo['toolInfoObjDetails'].map((toolInfoDetail, detailIndex) =>
                                                <div key={detailIndex} className="tool-info-details">
                                                    <div><CheckIcon /></div>
                                                    <div>
                                                        <span className="text-small-5">
                                                            {toolInfoDetail['name']}
                                                        </span>
                                                        {/* {toolInfoDetail['designation'] && ':'} <span className="text-small-2">{toolInfoDetail['designation']}</span> */}
                                                    </div>
                                                </div>
                                            ) }
                                        </ul>}
                                    </div>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}
