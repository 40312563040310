const sidenav = (state = {}, action) => {
    switch (action.type) {
      case 'GET_COMPONENTS':
        return {
            ...state,
            components : action.response
        };
      default:
        return state;
    }
  }
  
  export default sidenav;