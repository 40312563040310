import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import SideNavList from '../SideNavList/SideNavList';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getComponents from '../../actions/sidenav';
import EventNoteIcon from '@material-ui/icons/EventNote';
import EventIcon from '@material-ui/icons/Event';
import NextWeek from '@material-ui/icons/NextWeek';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {filterBy} from '../../utils/filter';
import './SideNav.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '400px',
  },
  fullList: {
    width: 'auto',
  },
  navHeader: {
    display: 'inherit'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function SideNav({ open, onToggle, componentsData, login, loading, dispatch, username, role}) {
  const history = useHistory();
  const token = login && login.token ? login.token : localStorage.getItem('usertoken');
  const [myComps, setMyComps] = useState([]);
  let body = {};

  function getMyComponents(){
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'token': login }
    };
    dispatch({ type: 'LOADING', response: true });
    fetch('/api/sf/mycomponents', requestOptions)
    .then(response => response.json())
    .then(data => {
        if (data) {
            console.log(data);
            setMyComps(data);
            dispatch({ type: 'LOADING', response: false });
        }
      })
      .catch(err =>{
          console.log(err);
          dispatch({ type: 'LOADING', response: false });
      })
  }

  React.useEffect(() => {
      dispatch(getComponents(token));
      getMyComponents()
  }, []);

  const classes = useStyles();

  const toDashboard = () => {
    history.push('/dashboard/');
    onToggle();
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })} role="presentation">
      <List disablePadding={true} className="list-header" onClick={toDashboard}>
          <ListItem button className={classes.navHeader} key="header">
            <Typography variant="h5" gutterBottom color="textPrimary">
              <span className="text-bold text-level-10">Salesforce Cozone</span>
            </Typography>
            <Typography variant="subtitle1" gutterBottom key="username">
              <span className="text text-small-2">{username.name}</span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom key="email">
              <span className="text text-small-2">{username.email}</span>
            </Typography>
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button className="lightning-components-aura">
          {componentsData && componentsData.length &&
          <SideNavList close={onToggle} title={`Lightning Components (Aura) (${filterBy(componentsData,"type",["component","record_page"]).length})`} components={filterBy(componentsData,"type",["component","record_page"])}/>
          }
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button className="lightning-components-web">
          {componentsData && componentsData.length &&
            <SideNavList close={onToggle} title={`Lightning Web Components (${filterBy(componentsData,"type",["LWC","lwc_record_page"]).length})`} components={filterBy(componentsData,"type",["LWC","lwc_record_page"])}/>
          }
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button>
          <div className="expansion-panel" style={{width:'100%'}}>
            <ExpansionPanel style={{boxShadow: "initial"}}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>Lightning Trainings & Documents</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <List dense={true}>
                  <ListItem button onClick={()=>{onToggle();history.push("/trainingCalendar")}}>
                    <ListItemIcon><EventIcon /></ListItemIcon>
                    <ListItemText primary='Training Calendar' />
                  </ListItem>
                  <ListItem button onClick={()=>{onToggle();history.push("/trainingContent")}}>
                    <ListItemIcon><EventIcon /></ListItemIcon>
                    <ListItemText primary='Lightning Documents' />
                  </ListItem>
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button>
          <div className="expansion-panel" style={{width:'100%'}}>
            <ExpansionPanel style={{boxShadow: "initial"}}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>Lightning Events</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <List dense={true}>
                  <ListItem button onClick={()=>{onToggle();history.push("/submitUseCase")}}>
                    <ListItemIcon><NextWeek /></ListItemIcon>
                    <ListItemText primary='Use Case of the month' />
                  </ListItem>
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button>
          <div className="expansion-panel" style={{width:'100%'}}>
            <ExpansionPanel style={{boxShadow: "initial"}}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>My Submitted Component</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <List dense={true}>
                  {myComps&& myComps.length>0 && myComps.map((item) =>{
                    if(item) {
                      if (item && item.Title && item.Status === 'Published') {
                        return (
                            <ListItem button onClick={() => {
                              onToggle();
                              history.push(`/component/${item.Id}`);
                            }} key={item.Title}>
                              <ListItemIcon><NextWeek/></ListItemIcon>
                              <ListItemText primary={item.Title}/>
                            </ListItem>);
                      } else if (item.Title) {
                        return (
                            <ListItem button disabled onClick={() => {
                            }} key={item.Title}>
                              <ListItemIcon><NextWeek/></ListItemIcon>
                              <ListItemText primary={item.Title}/>
                            </ListItem>);
                      }
                    }
                  })
                  }
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button onClick={()=>{onToggle();history.push("/submitComponent")}}>
            <ListItemIcon><CloudUploadIcon /></ListItemIcon>
            <ListItemText className="text-bold" primary='Submit Component' />
          </ListItem>
      </List>
      <List disablePadding={true}>
          <ListItem button onClick={()=>{onToggle();history.push("/mydownloads")}}>
            <ListItemIcon><OfflineBoltIcon/></ListItemIcon>
            <ListItemText primary='My Downloads' />
          </ListItem>
      </List>
      {role && role==='admin' && <List disablePadding={true}>
      <ListItem button onClick={()=>{onToggle();history.push("/users")}}>
        <ListItemIcon><SupervisorAccountIcon/></ListItemIcon>
        <ListItemText primary='Users' />
      </ListItem>
  </List>}
      <List disablePadding={true}>
          <ListItem button onClick={()=>{onToggle();history.push("/contactus")}}>
            <ListItemIcon><ContactSupportIcon /></ListItemIcon>
            <ListItemText primary='Contact Us' />
          </ListItem>
      </List>
    </div>
  );

  return (
    <div>
        <React.Fragment key='left'>
          <SwipeableDrawer
            className="left-drawer"
            anchor='left'
            open={open}
            onClose={onToggle}
            onOpen={onToggle}
          >
            {list('left')}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}


SideNav.propTypes = {
  login: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
      loading: state.loading,
      componentsData: state.sidenav.components,
      login: state.login.login,
      role: state.login.role
  }
}

export default connect(mapStateToProps)(SideNav);
