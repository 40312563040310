import { combineReducers } from 'redux';
import login from './login';
import dashboard from './dashboard';
import loading from './loading';
import sidenav from './sidenav';

export default combineReducers({
    login,
    dashboard,
    loading,
    sidenav
})